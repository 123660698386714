/* ====================================================
reset style
==================================================== */

html {
    font-size: 10px; /* Base font size, change to 14px or 16px if needed */
    overflow-y: scroll;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
figure {
    margin: 0;
    padding: 0;
}

* {
    box-sizing: border-box;
}

main {
    display: block;
}

table {
    border-spacing: 0;
    border-collapse: collapse;
}

fieldset,
img {
    border: 0;
}

img {
    max-width: 100%;

    vertical-align: middle;
}

address,
caption,
cite,
code,
dfn,
em,
th,
var {
    font-weight: normal;
    font-style: normal;
}

strong {
    font-weight: bold;
}

ul {
    li {
        list-style: none;
    }
}

ol {
    counter-reset: number;
    li {
        display: list-item;
        &:before {
            content: counter(number) '. ';
            counter-increment: number;
        }
    }
}

caption,
th {
    text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 100%;
    font-weight: normal;
}

q:before,
q:after {
    content: '';
}

abbr,
acronym {
    font-variant: normal;

    border: 0;
}

sup {
    vertical-align: text-top;
}

sub {
    vertical-align: text-bottom;
}

input,
textarea,
select {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
}

a {
    text-decoration: none;
}

/*to enable resizing for IE*/
input,
textarea,
select {
    *font-size: 100%;
}

/*because legend doesn't inherit in IE */
legend {
    color: #000000;
}

del,
ins {
    text-decoration: none;
}
