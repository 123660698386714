@charset "UTF-8";
/* ====================================================
reset style
==================================================== */
html {
  font-size: 10px;
  /* Base font size, change to 14px or 16px if needed */
  overflow-y: scroll;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
figure {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

main {
  display: block;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

fieldset,
img {
  border: 0;
}

img {
  max-width: 100%;
  vertical-align: middle;
}

address,
caption,
cite,
code,
dfn,
em,
th,
var {
  font-weight: normal;
  font-style: normal;
}

strong {
  font-weight: bold;
}

ul li {
  list-style: none;
}

ol {
  counter-reset: number;
}

ol li {
  display: list-item;
}

ol li:before {
  content: counter(number) ". ";
  counter-increment: number;
}

caption,
th {
  text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

q:before,
q:after {
  content: '';
}

abbr,
acronym {
  font-variant: normal;
  border: 0;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

input,
textarea,
select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

a {
  text-decoration: none;
}

/*to enable resizing for IE*/
input,
textarea,
select {
  *font-size: 100%;
}

/*because legend doesn't inherit in IE */
legend {
  color: #000000;
}

del,
ins {
  text-decoration: none;
}

/* ====================================================
Font
==================================================== */
@font-face {
  font-family: 'southpawregular';
  src: url("../fonts/Southpaw/southpaw-webfont.eot");
  src: url("../fonts/Southpaw/southpaw-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/Southpaw/southpaw-webfont.woff") format("woff"), url("../fonts/Southpaw/southpaw-webfont.ttf") format("truetype"), url("../fonts/Southpaw/southpaw-webfont.svg#southpawregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DIN Condensed Bold';
  font-style: normal;
  font-weight: normal;
  src: local("DIN Condensed Bold"), url("../fonts/DinCondensed/DINCondensed-Bold.woff") format("woff");
}

@font-face {
  font-family: "Noto Sans CJK JP";
  font-style: normal;
  font-weight: 100;
  src: url("../fonts/NotoSans/NotoSansCJKjp-Thin.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/NotoSans/NotoSansCJKjp-Thin.eot?#iefix") format("embedded-opentype"), url("../fonts/NotoSans/NotoSansCJKjp-Thin.woff2") format("woff2"), url("../fonts/NotoSans/NotoSansCJKjp-Thin.woff") format("woff"), url("../fonts/NotoSans/NotoSansCJKjp-Thin.otf") format("opentype");
  /* Safari, Android, iOS */
}

@font-face {
  font-family: "Noto Sans CJK JP";
  font-style: normal;
  font-weight: 200;
  src: url("../fonts/NotoSans/NotoSansCJKjp-Light.eot");
  src: local("Noto Sans CJK JP Light"), url("../fonts/NotoSans/NotoSansCJKjp-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/NotoSans/NotoSansCJKjp-Light.woff2") format("woff2"), url("../fonts/NotoSans/NotoSansCJKjp-Light.woff") format("woff"), url("../fonts/NotoSans/NotoSansCJKjp-Light.otf") format("opentype");
}

@font-face {
  font-family: "Noto Sans CJK JP";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/NotoSans/NotoSansCJKjp-DemiLight.eot");
  src: local("Noto Sans CJK JP DemiLight"), url("../fonts/NotoSans/NotoSansCJKjp-DemiLight.eot?#iefix") format("embedded-opentype"), url("../fonts/NotoSans/NotoSansCJKjp-DemiLight.woff2") format("woff2"), url("../fonts/NotoSans/NotoSansCJKjp-DemiLight.woff") format("woff"), url("../fonts/NotoSans/NotoSansCJKjp-DemiLight.otf") format("opentype");
}

@font-face {
  font-family: "Noto Sans CJK JP";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/NotoSans/NotoSansCJKjp-Regular.eot");
  src: local("Noto Sans CJK JP Regular"), url("../fonts/NotoSans/NotoSansCJKjp-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/NotoSans/NotoSansCJKjp-Regular.woff2") format("woff2"), url("../fonts/NotoSans/NotoSansCJKjp-Regular.woff") format("woff"), url("../fonts/NotoSans/NotoSansCJKjp-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Noto Sans CJK JP";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/NotoSans/NotoSansCJKjp-Medium.eot");
  src: local("Noto Sans CJK JP Medium"), url("../fonts/NotoSans/NotoSansCJKjp-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/NotoSans/NotoSansCJKjp-Medium.woff2") format("woff2"), url("../fonts/NotoSans/NotoSansCJKjp-Medium.woff") format("woff"), url("../fonts/NotoSans/NotoSansCJKjp-Medium.otf") format("opentype");
}

@font-face {
  font-family: "Noto Sans CJK JP";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/NotoSans/NotoSansCJKjp-Bold.eot");
  src: local("Noto Sans CJK JP Bold"), url("../fonts/NotoSans/NotoSansCJKjp-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/NotoSans/NotoSansCJKjp-Bold.woff2") format("woff2"), url("../fonts/NotoSans/NotoSansCJKjp-Bold.woff") format("woff"), url("../fonts/NotoSans/NotoSansCJKjp-Bold.otf") format("opentype");
}

@font-face {
  font-family: "Noto Sans CJK JP";
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/NotoSans/NotoSansCJKjp-Black.eot");
  src: local("Noto Sans CJK JP Black"), url("../fonts/NotoSans/NotoSansCJKjp-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/NotoSans/NotoSansCJKjp-Black.woff2") format("woff2"), url("../fonts/NotoSans/NotoSansCJKjp-Black.woff") format("woff"), url("../fonts/NotoSans/NotoSansCJKjp-Black.otf") format("opentype");
}

@font-face {
  font-family: "Noto Sans Mono CJK JP";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/NotoSans/NotoSansMonoCJKjp-Regular.eot");
  src: url("../fonts/NotoSans/NotoSansMonoCJKjp-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/NotoSans/NotoSansMonoCJKjp-Regular.woff") format("woff"), url("../fonts/NotoSans/NotoSansMonoCJKjp-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Noto Sans Mono CJK JP";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/NotoSans/NotoSansMonoCJKjp-Bold.eot");
  src: url("../fonts/NotoSans/NotoSansMonoCJKjp-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/NotoSans/NotoSansMonoCJKjp-Bold.woff") format("woff"), url("../fonts/NotoSans/NotoSansMonoCJKjp-Bold.otf") format("opentype");
}

/* ====================================================
Position & transform
==================================================== */
/* ====================================================
Color
==================================================== */
/* ====================================================
Sizing
==================================================== */
@media screen and (min-width: 769px) {
  .mtpc-1 {
    margin-top: 1px !important;
  }
  .mlpc-1 {
    margin-left: 1px !important;
  }
  .mrpc-1 {
    margin-right: 1px !important;
  }
  .mbpc-1 {
    margin-bottom: 1px !important;
  }
  .ptpc-1 {
    padding-top: 1px !important;
  }
  .plpc-1 {
    padding-left: 1px !important;
  }
  .prpc-1 {
    padding-right: 1px !important;
  }
  .pbpc-1 {
    padding-bottom: 1px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-1 {
    margin-top: 0.26667vw !important;
  }
  .mlsp-1 {
    margin-left: 0.26667vw !important;
  }
  .mrsp-1 {
    margin-right: 0.26667vw !important;
  }
  .mbsp-1 {
    margin-bottom: 0.26667vw !important;
  }
  .ptsp-1 {
    padding-top: 0.26667vw !important;
  }
  .plsp-1 {
    padding-left: 0.26667vw !important;
  }
  .prsp-1 {
    padding-right: 0.26667vw !important;
  }
  .pbsp-1 {
    padding-bottom: 0.26667vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-2 {
    margin-top: 2px !important;
  }
  .mlpc-2 {
    margin-left: 2px !important;
  }
  .mrpc-2 {
    margin-right: 2px !important;
  }
  .mbpc-2 {
    margin-bottom: 2px !important;
  }
  .ptpc-2 {
    padding-top: 2px !important;
  }
  .plpc-2 {
    padding-left: 2px !important;
  }
  .prpc-2 {
    padding-right: 2px !important;
  }
  .pbpc-2 {
    padding-bottom: 2px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-2 {
    margin-top: 0.53333vw !important;
  }
  .mlsp-2 {
    margin-left: 0.53333vw !important;
  }
  .mrsp-2 {
    margin-right: 0.53333vw !important;
  }
  .mbsp-2 {
    margin-bottom: 0.53333vw !important;
  }
  .ptsp-2 {
    padding-top: 0.53333vw !important;
  }
  .plsp-2 {
    padding-left: 0.53333vw !important;
  }
  .prsp-2 {
    padding-right: 0.53333vw !important;
  }
  .pbsp-2 {
    padding-bottom: 0.53333vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-3 {
    margin-top: 3px !important;
  }
  .mlpc-3 {
    margin-left: 3px !important;
  }
  .mrpc-3 {
    margin-right: 3px !important;
  }
  .mbpc-3 {
    margin-bottom: 3px !important;
  }
  .ptpc-3 {
    padding-top: 3px !important;
  }
  .plpc-3 {
    padding-left: 3px !important;
  }
  .prpc-3 {
    padding-right: 3px !important;
  }
  .pbpc-3 {
    padding-bottom: 3px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-3 {
    margin-top: 0.8vw !important;
  }
  .mlsp-3 {
    margin-left: 0.8vw !important;
  }
  .mrsp-3 {
    margin-right: 0.8vw !important;
  }
  .mbsp-3 {
    margin-bottom: 0.8vw !important;
  }
  .ptsp-3 {
    padding-top: 0.8vw !important;
  }
  .plsp-3 {
    padding-left: 0.8vw !important;
  }
  .prsp-3 {
    padding-right: 0.8vw !important;
  }
  .pbsp-3 {
    padding-bottom: 0.8vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-4 {
    margin-top: 4px !important;
  }
  .mlpc-4 {
    margin-left: 4px !important;
  }
  .mrpc-4 {
    margin-right: 4px !important;
  }
  .mbpc-4 {
    margin-bottom: 4px !important;
  }
  .ptpc-4 {
    padding-top: 4px !important;
  }
  .plpc-4 {
    padding-left: 4px !important;
  }
  .prpc-4 {
    padding-right: 4px !important;
  }
  .pbpc-4 {
    padding-bottom: 4px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-4 {
    margin-top: 1.06667vw !important;
  }
  .mlsp-4 {
    margin-left: 1.06667vw !important;
  }
  .mrsp-4 {
    margin-right: 1.06667vw !important;
  }
  .mbsp-4 {
    margin-bottom: 1.06667vw !important;
  }
  .ptsp-4 {
    padding-top: 1.06667vw !important;
  }
  .plsp-4 {
    padding-left: 1.06667vw !important;
  }
  .prsp-4 {
    padding-right: 1.06667vw !important;
  }
  .pbsp-4 {
    padding-bottom: 1.06667vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-5 {
    margin-top: 5px !important;
  }
  .mlpc-5 {
    margin-left: 5px !important;
  }
  .mrpc-5 {
    margin-right: 5px !important;
  }
  .mbpc-5 {
    margin-bottom: 5px !important;
  }
  .ptpc-5 {
    padding-top: 5px !important;
  }
  .plpc-5 {
    padding-left: 5px !important;
  }
  .prpc-5 {
    padding-right: 5px !important;
  }
  .pbpc-5 {
    padding-bottom: 5px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-5 {
    margin-top: 1.33333vw !important;
  }
  .mlsp-5 {
    margin-left: 1.33333vw !important;
  }
  .mrsp-5 {
    margin-right: 1.33333vw !important;
  }
  .mbsp-5 {
    margin-bottom: 1.33333vw !important;
  }
  .ptsp-5 {
    padding-top: 1.33333vw !important;
  }
  .plsp-5 {
    padding-left: 1.33333vw !important;
  }
  .prsp-5 {
    padding-right: 1.33333vw !important;
  }
  .pbsp-5 {
    padding-bottom: 1.33333vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-6 {
    margin-top: 6px !important;
  }
  .mlpc-6 {
    margin-left: 6px !important;
  }
  .mrpc-6 {
    margin-right: 6px !important;
  }
  .mbpc-6 {
    margin-bottom: 6px !important;
  }
  .ptpc-6 {
    padding-top: 6px !important;
  }
  .plpc-6 {
    padding-left: 6px !important;
  }
  .prpc-6 {
    padding-right: 6px !important;
  }
  .pbpc-6 {
    padding-bottom: 6px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-6 {
    margin-top: 1.6vw !important;
  }
  .mlsp-6 {
    margin-left: 1.6vw !important;
  }
  .mrsp-6 {
    margin-right: 1.6vw !important;
  }
  .mbsp-6 {
    margin-bottom: 1.6vw !important;
  }
  .ptsp-6 {
    padding-top: 1.6vw !important;
  }
  .plsp-6 {
    padding-left: 1.6vw !important;
  }
  .prsp-6 {
    padding-right: 1.6vw !important;
  }
  .pbsp-6 {
    padding-bottom: 1.6vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-7 {
    margin-top: 7px !important;
  }
  .mlpc-7 {
    margin-left: 7px !important;
  }
  .mrpc-7 {
    margin-right: 7px !important;
  }
  .mbpc-7 {
    margin-bottom: 7px !important;
  }
  .ptpc-7 {
    padding-top: 7px !important;
  }
  .plpc-7 {
    padding-left: 7px !important;
  }
  .prpc-7 {
    padding-right: 7px !important;
  }
  .pbpc-7 {
    padding-bottom: 7px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-7 {
    margin-top: 1.86667vw !important;
  }
  .mlsp-7 {
    margin-left: 1.86667vw !important;
  }
  .mrsp-7 {
    margin-right: 1.86667vw !important;
  }
  .mbsp-7 {
    margin-bottom: 1.86667vw !important;
  }
  .ptsp-7 {
    padding-top: 1.86667vw !important;
  }
  .plsp-7 {
    padding-left: 1.86667vw !important;
  }
  .prsp-7 {
    padding-right: 1.86667vw !important;
  }
  .pbsp-7 {
    padding-bottom: 1.86667vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-8 {
    margin-top: 8px !important;
  }
  .mlpc-8 {
    margin-left: 8px !important;
  }
  .mrpc-8 {
    margin-right: 8px !important;
  }
  .mbpc-8 {
    margin-bottom: 8px !important;
  }
  .ptpc-8 {
    padding-top: 8px !important;
  }
  .plpc-8 {
    padding-left: 8px !important;
  }
  .prpc-8 {
    padding-right: 8px !important;
  }
  .pbpc-8 {
    padding-bottom: 8px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-8 {
    margin-top: 2.13333vw !important;
  }
  .mlsp-8 {
    margin-left: 2.13333vw !important;
  }
  .mrsp-8 {
    margin-right: 2.13333vw !important;
  }
  .mbsp-8 {
    margin-bottom: 2.13333vw !important;
  }
  .ptsp-8 {
    padding-top: 2.13333vw !important;
  }
  .plsp-8 {
    padding-left: 2.13333vw !important;
  }
  .prsp-8 {
    padding-right: 2.13333vw !important;
  }
  .pbsp-8 {
    padding-bottom: 2.13333vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-9 {
    margin-top: 9px !important;
  }
  .mlpc-9 {
    margin-left: 9px !important;
  }
  .mrpc-9 {
    margin-right: 9px !important;
  }
  .mbpc-9 {
    margin-bottom: 9px !important;
  }
  .ptpc-9 {
    padding-top: 9px !important;
  }
  .plpc-9 {
    padding-left: 9px !important;
  }
  .prpc-9 {
    padding-right: 9px !important;
  }
  .pbpc-9 {
    padding-bottom: 9px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-9 {
    margin-top: 2.4vw !important;
  }
  .mlsp-9 {
    margin-left: 2.4vw !important;
  }
  .mrsp-9 {
    margin-right: 2.4vw !important;
  }
  .mbsp-9 {
    margin-bottom: 2.4vw !important;
  }
  .ptsp-9 {
    padding-top: 2.4vw !important;
  }
  .plsp-9 {
    padding-left: 2.4vw !important;
  }
  .prsp-9 {
    padding-right: 2.4vw !important;
  }
  .pbsp-9 {
    padding-bottom: 2.4vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-10 {
    margin-top: 10px !important;
  }
  .mlpc-10 {
    margin-left: 10px !important;
  }
  .mrpc-10 {
    margin-right: 10px !important;
  }
  .mbpc-10 {
    margin-bottom: 10px !important;
  }
  .ptpc-10 {
    padding-top: 10px !important;
  }
  .plpc-10 {
    padding-left: 10px !important;
  }
  .prpc-10 {
    padding-right: 10px !important;
  }
  .pbpc-10 {
    padding-bottom: 10px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-10 {
    margin-top: 2.66667vw !important;
  }
  .mlsp-10 {
    margin-left: 2.66667vw !important;
  }
  .mrsp-10 {
    margin-right: 2.66667vw !important;
  }
  .mbsp-10 {
    margin-bottom: 2.66667vw !important;
  }
  .ptsp-10 {
    padding-top: 2.66667vw !important;
  }
  .plsp-10 {
    padding-left: 2.66667vw !important;
  }
  .prsp-10 {
    padding-right: 2.66667vw !important;
  }
  .pbsp-10 {
    padding-bottom: 2.66667vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-11 {
    margin-top: 11px !important;
  }
  .mlpc-11 {
    margin-left: 11px !important;
  }
  .mrpc-11 {
    margin-right: 11px !important;
  }
  .mbpc-11 {
    margin-bottom: 11px !important;
  }
  .ptpc-11 {
    padding-top: 11px !important;
  }
  .plpc-11 {
    padding-left: 11px !important;
  }
  .prpc-11 {
    padding-right: 11px !important;
  }
  .pbpc-11 {
    padding-bottom: 11px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-11 {
    margin-top: 2.93333vw !important;
  }
  .mlsp-11 {
    margin-left: 2.93333vw !important;
  }
  .mrsp-11 {
    margin-right: 2.93333vw !important;
  }
  .mbsp-11 {
    margin-bottom: 2.93333vw !important;
  }
  .ptsp-11 {
    padding-top: 2.93333vw !important;
  }
  .plsp-11 {
    padding-left: 2.93333vw !important;
  }
  .prsp-11 {
    padding-right: 2.93333vw !important;
  }
  .pbsp-11 {
    padding-bottom: 2.93333vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-12 {
    margin-top: 12px !important;
  }
  .mlpc-12 {
    margin-left: 12px !important;
  }
  .mrpc-12 {
    margin-right: 12px !important;
  }
  .mbpc-12 {
    margin-bottom: 12px !important;
  }
  .ptpc-12 {
    padding-top: 12px !important;
  }
  .plpc-12 {
    padding-left: 12px !important;
  }
  .prpc-12 {
    padding-right: 12px !important;
  }
  .pbpc-12 {
    padding-bottom: 12px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-12 {
    margin-top: 3.2vw !important;
  }
  .mlsp-12 {
    margin-left: 3.2vw !important;
  }
  .mrsp-12 {
    margin-right: 3.2vw !important;
  }
  .mbsp-12 {
    margin-bottom: 3.2vw !important;
  }
  .ptsp-12 {
    padding-top: 3.2vw !important;
  }
  .plsp-12 {
    padding-left: 3.2vw !important;
  }
  .prsp-12 {
    padding-right: 3.2vw !important;
  }
  .pbsp-12 {
    padding-bottom: 3.2vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-13 {
    margin-top: 13px !important;
  }
  .mlpc-13 {
    margin-left: 13px !important;
  }
  .mrpc-13 {
    margin-right: 13px !important;
  }
  .mbpc-13 {
    margin-bottom: 13px !important;
  }
  .ptpc-13 {
    padding-top: 13px !important;
  }
  .plpc-13 {
    padding-left: 13px !important;
  }
  .prpc-13 {
    padding-right: 13px !important;
  }
  .pbpc-13 {
    padding-bottom: 13px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-13 {
    margin-top: 3.46667vw !important;
  }
  .mlsp-13 {
    margin-left: 3.46667vw !important;
  }
  .mrsp-13 {
    margin-right: 3.46667vw !important;
  }
  .mbsp-13 {
    margin-bottom: 3.46667vw !important;
  }
  .ptsp-13 {
    padding-top: 3.46667vw !important;
  }
  .plsp-13 {
    padding-left: 3.46667vw !important;
  }
  .prsp-13 {
    padding-right: 3.46667vw !important;
  }
  .pbsp-13 {
    padding-bottom: 3.46667vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-14 {
    margin-top: 14px !important;
  }
  .mlpc-14 {
    margin-left: 14px !important;
  }
  .mrpc-14 {
    margin-right: 14px !important;
  }
  .mbpc-14 {
    margin-bottom: 14px !important;
  }
  .ptpc-14 {
    padding-top: 14px !important;
  }
  .plpc-14 {
    padding-left: 14px !important;
  }
  .prpc-14 {
    padding-right: 14px !important;
  }
  .pbpc-14 {
    padding-bottom: 14px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-14 {
    margin-top: 3.73333vw !important;
  }
  .mlsp-14 {
    margin-left: 3.73333vw !important;
  }
  .mrsp-14 {
    margin-right: 3.73333vw !important;
  }
  .mbsp-14 {
    margin-bottom: 3.73333vw !important;
  }
  .ptsp-14 {
    padding-top: 3.73333vw !important;
  }
  .plsp-14 {
    padding-left: 3.73333vw !important;
  }
  .prsp-14 {
    padding-right: 3.73333vw !important;
  }
  .pbsp-14 {
    padding-bottom: 3.73333vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-15 {
    margin-top: 15px !important;
  }
  .mlpc-15 {
    margin-left: 15px !important;
  }
  .mrpc-15 {
    margin-right: 15px !important;
  }
  .mbpc-15 {
    margin-bottom: 15px !important;
  }
  .ptpc-15 {
    padding-top: 15px !important;
  }
  .plpc-15 {
    padding-left: 15px !important;
  }
  .prpc-15 {
    padding-right: 15px !important;
  }
  .pbpc-15 {
    padding-bottom: 15px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-15 {
    margin-top: 4vw !important;
  }
  .mlsp-15 {
    margin-left: 4vw !important;
  }
  .mrsp-15 {
    margin-right: 4vw !important;
  }
  .mbsp-15 {
    margin-bottom: 4vw !important;
  }
  .ptsp-15 {
    padding-top: 4vw !important;
  }
  .plsp-15 {
    padding-left: 4vw !important;
  }
  .prsp-15 {
    padding-right: 4vw !important;
  }
  .pbsp-15 {
    padding-bottom: 4vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-16 {
    margin-top: 16px !important;
  }
  .mlpc-16 {
    margin-left: 16px !important;
  }
  .mrpc-16 {
    margin-right: 16px !important;
  }
  .mbpc-16 {
    margin-bottom: 16px !important;
  }
  .ptpc-16 {
    padding-top: 16px !important;
  }
  .plpc-16 {
    padding-left: 16px !important;
  }
  .prpc-16 {
    padding-right: 16px !important;
  }
  .pbpc-16 {
    padding-bottom: 16px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-16 {
    margin-top: 4.26667vw !important;
  }
  .mlsp-16 {
    margin-left: 4.26667vw !important;
  }
  .mrsp-16 {
    margin-right: 4.26667vw !important;
  }
  .mbsp-16 {
    margin-bottom: 4.26667vw !important;
  }
  .ptsp-16 {
    padding-top: 4.26667vw !important;
  }
  .plsp-16 {
    padding-left: 4.26667vw !important;
  }
  .prsp-16 {
    padding-right: 4.26667vw !important;
  }
  .pbsp-16 {
    padding-bottom: 4.26667vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-17 {
    margin-top: 17px !important;
  }
  .mlpc-17 {
    margin-left: 17px !important;
  }
  .mrpc-17 {
    margin-right: 17px !important;
  }
  .mbpc-17 {
    margin-bottom: 17px !important;
  }
  .ptpc-17 {
    padding-top: 17px !important;
  }
  .plpc-17 {
    padding-left: 17px !important;
  }
  .prpc-17 {
    padding-right: 17px !important;
  }
  .pbpc-17 {
    padding-bottom: 17px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-17 {
    margin-top: 4.53333vw !important;
  }
  .mlsp-17 {
    margin-left: 4.53333vw !important;
  }
  .mrsp-17 {
    margin-right: 4.53333vw !important;
  }
  .mbsp-17 {
    margin-bottom: 4.53333vw !important;
  }
  .ptsp-17 {
    padding-top: 4.53333vw !important;
  }
  .plsp-17 {
    padding-left: 4.53333vw !important;
  }
  .prsp-17 {
    padding-right: 4.53333vw !important;
  }
  .pbsp-17 {
    padding-bottom: 4.53333vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-18 {
    margin-top: 18px !important;
  }
  .mlpc-18 {
    margin-left: 18px !important;
  }
  .mrpc-18 {
    margin-right: 18px !important;
  }
  .mbpc-18 {
    margin-bottom: 18px !important;
  }
  .ptpc-18 {
    padding-top: 18px !important;
  }
  .plpc-18 {
    padding-left: 18px !important;
  }
  .prpc-18 {
    padding-right: 18px !important;
  }
  .pbpc-18 {
    padding-bottom: 18px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-18 {
    margin-top: 4.8vw !important;
  }
  .mlsp-18 {
    margin-left: 4.8vw !important;
  }
  .mrsp-18 {
    margin-right: 4.8vw !important;
  }
  .mbsp-18 {
    margin-bottom: 4.8vw !important;
  }
  .ptsp-18 {
    padding-top: 4.8vw !important;
  }
  .plsp-18 {
    padding-left: 4.8vw !important;
  }
  .prsp-18 {
    padding-right: 4.8vw !important;
  }
  .pbsp-18 {
    padding-bottom: 4.8vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-19 {
    margin-top: 19px !important;
  }
  .mlpc-19 {
    margin-left: 19px !important;
  }
  .mrpc-19 {
    margin-right: 19px !important;
  }
  .mbpc-19 {
    margin-bottom: 19px !important;
  }
  .ptpc-19 {
    padding-top: 19px !important;
  }
  .plpc-19 {
    padding-left: 19px !important;
  }
  .prpc-19 {
    padding-right: 19px !important;
  }
  .pbpc-19 {
    padding-bottom: 19px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-19 {
    margin-top: 5.06667vw !important;
  }
  .mlsp-19 {
    margin-left: 5.06667vw !important;
  }
  .mrsp-19 {
    margin-right: 5.06667vw !important;
  }
  .mbsp-19 {
    margin-bottom: 5.06667vw !important;
  }
  .ptsp-19 {
    padding-top: 5.06667vw !important;
  }
  .plsp-19 {
    padding-left: 5.06667vw !important;
  }
  .prsp-19 {
    padding-right: 5.06667vw !important;
  }
  .pbsp-19 {
    padding-bottom: 5.06667vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-20 {
    margin-top: 20px !important;
  }
  .mlpc-20 {
    margin-left: 20px !important;
  }
  .mrpc-20 {
    margin-right: 20px !important;
  }
  .mbpc-20 {
    margin-bottom: 20px !important;
  }
  .ptpc-20 {
    padding-top: 20px !important;
  }
  .plpc-20 {
    padding-left: 20px !important;
  }
  .prpc-20 {
    padding-right: 20px !important;
  }
  .pbpc-20 {
    padding-bottom: 20px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-20 {
    margin-top: 5.33333vw !important;
  }
  .mlsp-20 {
    margin-left: 5.33333vw !important;
  }
  .mrsp-20 {
    margin-right: 5.33333vw !important;
  }
  .mbsp-20 {
    margin-bottom: 5.33333vw !important;
  }
  .ptsp-20 {
    padding-top: 5.33333vw !important;
  }
  .plsp-20 {
    padding-left: 5.33333vw !important;
  }
  .prsp-20 {
    padding-right: 5.33333vw !important;
  }
  .pbsp-20 {
    padding-bottom: 5.33333vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-21 {
    margin-top: 21px !important;
  }
  .mlpc-21 {
    margin-left: 21px !important;
  }
  .mrpc-21 {
    margin-right: 21px !important;
  }
  .mbpc-21 {
    margin-bottom: 21px !important;
  }
  .ptpc-21 {
    padding-top: 21px !important;
  }
  .plpc-21 {
    padding-left: 21px !important;
  }
  .prpc-21 {
    padding-right: 21px !important;
  }
  .pbpc-21 {
    padding-bottom: 21px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-21 {
    margin-top: 5.6vw !important;
  }
  .mlsp-21 {
    margin-left: 5.6vw !important;
  }
  .mrsp-21 {
    margin-right: 5.6vw !important;
  }
  .mbsp-21 {
    margin-bottom: 5.6vw !important;
  }
  .ptsp-21 {
    padding-top: 5.6vw !important;
  }
  .plsp-21 {
    padding-left: 5.6vw !important;
  }
  .prsp-21 {
    padding-right: 5.6vw !important;
  }
  .pbsp-21 {
    padding-bottom: 5.6vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-22 {
    margin-top: 22px !important;
  }
  .mlpc-22 {
    margin-left: 22px !important;
  }
  .mrpc-22 {
    margin-right: 22px !important;
  }
  .mbpc-22 {
    margin-bottom: 22px !important;
  }
  .ptpc-22 {
    padding-top: 22px !important;
  }
  .plpc-22 {
    padding-left: 22px !important;
  }
  .prpc-22 {
    padding-right: 22px !important;
  }
  .pbpc-22 {
    padding-bottom: 22px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-22 {
    margin-top: 5.86667vw !important;
  }
  .mlsp-22 {
    margin-left: 5.86667vw !important;
  }
  .mrsp-22 {
    margin-right: 5.86667vw !important;
  }
  .mbsp-22 {
    margin-bottom: 5.86667vw !important;
  }
  .ptsp-22 {
    padding-top: 5.86667vw !important;
  }
  .plsp-22 {
    padding-left: 5.86667vw !important;
  }
  .prsp-22 {
    padding-right: 5.86667vw !important;
  }
  .pbsp-22 {
    padding-bottom: 5.86667vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-23 {
    margin-top: 23px !important;
  }
  .mlpc-23 {
    margin-left: 23px !important;
  }
  .mrpc-23 {
    margin-right: 23px !important;
  }
  .mbpc-23 {
    margin-bottom: 23px !important;
  }
  .ptpc-23 {
    padding-top: 23px !important;
  }
  .plpc-23 {
    padding-left: 23px !important;
  }
  .prpc-23 {
    padding-right: 23px !important;
  }
  .pbpc-23 {
    padding-bottom: 23px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-23 {
    margin-top: 6.13333vw !important;
  }
  .mlsp-23 {
    margin-left: 6.13333vw !important;
  }
  .mrsp-23 {
    margin-right: 6.13333vw !important;
  }
  .mbsp-23 {
    margin-bottom: 6.13333vw !important;
  }
  .ptsp-23 {
    padding-top: 6.13333vw !important;
  }
  .plsp-23 {
    padding-left: 6.13333vw !important;
  }
  .prsp-23 {
    padding-right: 6.13333vw !important;
  }
  .pbsp-23 {
    padding-bottom: 6.13333vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-24 {
    margin-top: 24px !important;
  }
  .mlpc-24 {
    margin-left: 24px !important;
  }
  .mrpc-24 {
    margin-right: 24px !important;
  }
  .mbpc-24 {
    margin-bottom: 24px !important;
  }
  .ptpc-24 {
    padding-top: 24px !important;
  }
  .plpc-24 {
    padding-left: 24px !important;
  }
  .prpc-24 {
    padding-right: 24px !important;
  }
  .pbpc-24 {
    padding-bottom: 24px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-24 {
    margin-top: 6.4vw !important;
  }
  .mlsp-24 {
    margin-left: 6.4vw !important;
  }
  .mrsp-24 {
    margin-right: 6.4vw !important;
  }
  .mbsp-24 {
    margin-bottom: 6.4vw !important;
  }
  .ptsp-24 {
    padding-top: 6.4vw !important;
  }
  .plsp-24 {
    padding-left: 6.4vw !important;
  }
  .prsp-24 {
    padding-right: 6.4vw !important;
  }
  .pbsp-24 {
    padding-bottom: 6.4vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-25 {
    margin-top: 25px !important;
  }
  .mlpc-25 {
    margin-left: 25px !important;
  }
  .mrpc-25 {
    margin-right: 25px !important;
  }
  .mbpc-25 {
    margin-bottom: 25px !important;
  }
  .ptpc-25 {
    padding-top: 25px !important;
  }
  .plpc-25 {
    padding-left: 25px !important;
  }
  .prpc-25 {
    padding-right: 25px !important;
  }
  .pbpc-25 {
    padding-bottom: 25px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-25 {
    margin-top: 6.66667vw !important;
  }
  .mlsp-25 {
    margin-left: 6.66667vw !important;
  }
  .mrsp-25 {
    margin-right: 6.66667vw !important;
  }
  .mbsp-25 {
    margin-bottom: 6.66667vw !important;
  }
  .ptsp-25 {
    padding-top: 6.66667vw !important;
  }
  .plsp-25 {
    padding-left: 6.66667vw !important;
  }
  .prsp-25 {
    padding-right: 6.66667vw !important;
  }
  .pbsp-25 {
    padding-bottom: 6.66667vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-26 {
    margin-top: 26px !important;
  }
  .mlpc-26 {
    margin-left: 26px !important;
  }
  .mrpc-26 {
    margin-right: 26px !important;
  }
  .mbpc-26 {
    margin-bottom: 26px !important;
  }
  .ptpc-26 {
    padding-top: 26px !important;
  }
  .plpc-26 {
    padding-left: 26px !important;
  }
  .prpc-26 {
    padding-right: 26px !important;
  }
  .pbpc-26 {
    padding-bottom: 26px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-26 {
    margin-top: 6.93333vw !important;
  }
  .mlsp-26 {
    margin-left: 6.93333vw !important;
  }
  .mrsp-26 {
    margin-right: 6.93333vw !important;
  }
  .mbsp-26 {
    margin-bottom: 6.93333vw !important;
  }
  .ptsp-26 {
    padding-top: 6.93333vw !important;
  }
  .plsp-26 {
    padding-left: 6.93333vw !important;
  }
  .prsp-26 {
    padding-right: 6.93333vw !important;
  }
  .pbsp-26 {
    padding-bottom: 6.93333vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-27 {
    margin-top: 27px !important;
  }
  .mlpc-27 {
    margin-left: 27px !important;
  }
  .mrpc-27 {
    margin-right: 27px !important;
  }
  .mbpc-27 {
    margin-bottom: 27px !important;
  }
  .ptpc-27 {
    padding-top: 27px !important;
  }
  .plpc-27 {
    padding-left: 27px !important;
  }
  .prpc-27 {
    padding-right: 27px !important;
  }
  .pbpc-27 {
    padding-bottom: 27px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-27 {
    margin-top: 7.2vw !important;
  }
  .mlsp-27 {
    margin-left: 7.2vw !important;
  }
  .mrsp-27 {
    margin-right: 7.2vw !important;
  }
  .mbsp-27 {
    margin-bottom: 7.2vw !important;
  }
  .ptsp-27 {
    padding-top: 7.2vw !important;
  }
  .plsp-27 {
    padding-left: 7.2vw !important;
  }
  .prsp-27 {
    padding-right: 7.2vw !important;
  }
  .pbsp-27 {
    padding-bottom: 7.2vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-28 {
    margin-top: 28px !important;
  }
  .mlpc-28 {
    margin-left: 28px !important;
  }
  .mrpc-28 {
    margin-right: 28px !important;
  }
  .mbpc-28 {
    margin-bottom: 28px !important;
  }
  .ptpc-28 {
    padding-top: 28px !important;
  }
  .plpc-28 {
    padding-left: 28px !important;
  }
  .prpc-28 {
    padding-right: 28px !important;
  }
  .pbpc-28 {
    padding-bottom: 28px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-28 {
    margin-top: 7.46667vw !important;
  }
  .mlsp-28 {
    margin-left: 7.46667vw !important;
  }
  .mrsp-28 {
    margin-right: 7.46667vw !important;
  }
  .mbsp-28 {
    margin-bottom: 7.46667vw !important;
  }
  .ptsp-28 {
    padding-top: 7.46667vw !important;
  }
  .plsp-28 {
    padding-left: 7.46667vw !important;
  }
  .prsp-28 {
    padding-right: 7.46667vw !important;
  }
  .pbsp-28 {
    padding-bottom: 7.46667vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-29 {
    margin-top: 29px !important;
  }
  .mlpc-29 {
    margin-left: 29px !important;
  }
  .mrpc-29 {
    margin-right: 29px !important;
  }
  .mbpc-29 {
    margin-bottom: 29px !important;
  }
  .ptpc-29 {
    padding-top: 29px !important;
  }
  .plpc-29 {
    padding-left: 29px !important;
  }
  .prpc-29 {
    padding-right: 29px !important;
  }
  .pbpc-29 {
    padding-bottom: 29px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-29 {
    margin-top: 7.73333vw !important;
  }
  .mlsp-29 {
    margin-left: 7.73333vw !important;
  }
  .mrsp-29 {
    margin-right: 7.73333vw !important;
  }
  .mbsp-29 {
    margin-bottom: 7.73333vw !important;
  }
  .ptsp-29 {
    padding-top: 7.73333vw !important;
  }
  .plsp-29 {
    padding-left: 7.73333vw !important;
  }
  .prsp-29 {
    padding-right: 7.73333vw !important;
  }
  .pbsp-29 {
    padding-bottom: 7.73333vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-30 {
    margin-top: 30px !important;
  }
  .mlpc-30 {
    margin-left: 30px !important;
  }
  .mrpc-30 {
    margin-right: 30px !important;
  }
  .mbpc-30 {
    margin-bottom: 30px !important;
  }
  .ptpc-30 {
    padding-top: 30px !important;
  }
  .plpc-30 {
    padding-left: 30px !important;
  }
  .prpc-30 {
    padding-right: 30px !important;
  }
  .pbpc-30 {
    padding-bottom: 30px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-30 {
    margin-top: 8vw !important;
  }
  .mlsp-30 {
    margin-left: 8vw !important;
  }
  .mrsp-30 {
    margin-right: 8vw !important;
  }
  .mbsp-30 {
    margin-bottom: 8vw !important;
  }
  .ptsp-30 {
    padding-top: 8vw !important;
  }
  .plsp-30 {
    padding-left: 8vw !important;
  }
  .prsp-30 {
    padding-right: 8vw !important;
  }
  .pbsp-30 {
    padding-bottom: 8vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-31 {
    margin-top: 31px !important;
  }
  .mlpc-31 {
    margin-left: 31px !important;
  }
  .mrpc-31 {
    margin-right: 31px !important;
  }
  .mbpc-31 {
    margin-bottom: 31px !important;
  }
  .ptpc-31 {
    padding-top: 31px !important;
  }
  .plpc-31 {
    padding-left: 31px !important;
  }
  .prpc-31 {
    padding-right: 31px !important;
  }
  .pbpc-31 {
    padding-bottom: 31px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-31 {
    margin-top: 8.26667vw !important;
  }
  .mlsp-31 {
    margin-left: 8.26667vw !important;
  }
  .mrsp-31 {
    margin-right: 8.26667vw !important;
  }
  .mbsp-31 {
    margin-bottom: 8.26667vw !important;
  }
  .ptsp-31 {
    padding-top: 8.26667vw !important;
  }
  .plsp-31 {
    padding-left: 8.26667vw !important;
  }
  .prsp-31 {
    padding-right: 8.26667vw !important;
  }
  .pbsp-31 {
    padding-bottom: 8.26667vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-32 {
    margin-top: 32px !important;
  }
  .mlpc-32 {
    margin-left: 32px !important;
  }
  .mrpc-32 {
    margin-right: 32px !important;
  }
  .mbpc-32 {
    margin-bottom: 32px !important;
  }
  .ptpc-32 {
    padding-top: 32px !important;
  }
  .plpc-32 {
    padding-left: 32px !important;
  }
  .prpc-32 {
    padding-right: 32px !important;
  }
  .pbpc-32 {
    padding-bottom: 32px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-32 {
    margin-top: 8.53333vw !important;
  }
  .mlsp-32 {
    margin-left: 8.53333vw !important;
  }
  .mrsp-32 {
    margin-right: 8.53333vw !important;
  }
  .mbsp-32 {
    margin-bottom: 8.53333vw !important;
  }
  .ptsp-32 {
    padding-top: 8.53333vw !important;
  }
  .plsp-32 {
    padding-left: 8.53333vw !important;
  }
  .prsp-32 {
    padding-right: 8.53333vw !important;
  }
  .pbsp-32 {
    padding-bottom: 8.53333vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-33 {
    margin-top: 33px !important;
  }
  .mlpc-33 {
    margin-left: 33px !important;
  }
  .mrpc-33 {
    margin-right: 33px !important;
  }
  .mbpc-33 {
    margin-bottom: 33px !important;
  }
  .ptpc-33 {
    padding-top: 33px !important;
  }
  .plpc-33 {
    padding-left: 33px !important;
  }
  .prpc-33 {
    padding-right: 33px !important;
  }
  .pbpc-33 {
    padding-bottom: 33px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-33 {
    margin-top: 8.8vw !important;
  }
  .mlsp-33 {
    margin-left: 8.8vw !important;
  }
  .mrsp-33 {
    margin-right: 8.8vw !important;
  }
  .mbsp-33 {
    margin-bottom: 8.8vw !important;
  }
  .ptsp-33 {
    padding-top: 8.8vw !important;
  }
  .plsp-33 {
    padding-left: 8.8vw !important;
  }
  .prsp-33 {
    padding-right: 8.8vw !important;
  }
  .pbsp-33 {
    padding-bottom: 8.8vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-34 {
    margin-top: 34px !important;
  }
  .mlpc-34 {
    margin-left: 34px !important;
  }
  .mrpc-34 {
    margin-right: 34px !important;
  }
  .mbpc-34 {
    margin-bottom: 34px !important;
  }
  .ptpc-34 {
    padding-top: 34px !important;
  }
  .plpc-34 {
    padding-left: 34px !important;
  }
  .prpc-34 {
    padding-right: 34px !important;
  }
  .pbpc-34 {
    padding-bottom: 34px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-34 {
    margin-top: 9.06667vw !important;
  }
  .mlsp-34 {
    margin-left: 9.06667vw !important;
  }
  .mrsp-34 {
    margin-right: 9.06667vw !important;
  }
  .mbsp-34 {
    margin-bottom: 9.06667vw !important;
  }
  .ptsp-34 {
    padding-top: 9.06667vw !important;
  }
  .plsp-34 {
    padding-left: 9.06667vw !important;
  }
  .prsp-34 {
    padding-right: 9.06667vw !important;
  }
  .pbsp-34 {
    padding-bottom: 9.06667vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-35 {
    margin-top: 35px !important;
  }
  .mlpc-35 {
    margin-left: 35px !important;
  }
  .mrpc-35 {
    margin-right: 35px !important;
  }
  .mbpc-35 {
    margin-bottom: 35px !important;
  }
  .ptpc-35 {
    padding-top: 35px !important;
  }
  .plpc-35 {
    padding-left: 35px !important;
  }
  .prpc-35 {
    padding-right: 35px !important;
  }
  .pbpc-35 {
    padding-bottom: 35px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-35 {
    margin-top: 9.33333vw !important;
  }
  .mlsp-35 {
    margin-left: 9.33333vw !important;
  }
  .mrsp-35 {
    margin-right: 9.33333vw !important;
  }
  .mbsp-35 {
    margin-bottom: 9.33333vw !important;
  }
  .ptsp-35 {
    padding-top: 9.33333vw !important;
  }
  .plsp-35 {
    padding-left: 9.33333vw !important;
  }
  .prsp-35 {
    padding-right: 9.33333vw !important;
  }
  .pbsp-35 {
    padding-bottom: 9.33333vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-36 {
    margin-top: 36px !important;
  }
  .mlpc-36 {
    margin-left: 36px !important;
  }
  .mrpc-36 {
    margin-right: 36px !important;
  }
  .mbpc-36 {
    margin-bottom: 36px !important;
  }
  .ptpc-36 {
    padding-top: 36px !important;
  }
  .plpc-36 {
    padding-left: 36px !important;
  }
  .prpc-36 {
    padding-right: 36px !important;
  }
  .pbpc-36 {
    padding-bottom: 36px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-36 {
    margin-top: 9.6vw !important;
  }
  .mlsp-36 {
    margin-left: 9.6vw !important;
  }
  .mrsp-36 {
    margin-right: 9.6vw !important;
  }
  .mbsp-36 {
    margin-bottom: 9.6vw !important;
  }
  .ptsp-36 {
    padding-top: 9.6vw !important;
  }
  .plsp-36 {
    padding-left: 9.6vw !important;
  }
  .prsp-36 {
    padding-right: 9.6vw !important;
  }
  .pbsp-36 {
    padding-bottom: 9.6vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-37 {
    margin-top: 37px !important;
  }
  .mlpc-37 {
    margin-left: 37px !important;
  }
  .mrpc-37 {
    margin-right: 37px !important;
  }
  .mbpc-37 {
    margin-bottom: 37px !important;
  }
  .ptpc-37 {
    padding-top: 37px !important;
  }
  .plpc-37 {
    padding-left: 37px !important;
  }
  .prpc-37 {
    padding-right: 37px !important;
  }
  .pbpc-37 {
    padding-bottom: 37px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-37 {
    margin-top: 9.86667vw !important;
  }
  .mlsp-37 {
    margin-left: 9.86667vw !important;
  }
  .mrsp-37 {
    margin-right: 9.86667vw !important;
  }
  .mbsp-37 {
    margin-bottom: 9.86667vw !important;
  }
  .ptsp-37 {
    padding-top: 9.86667vw !important;
  }
  .plsp-37 {
    padding-left: 9.86667vw !important;
  }
  .prsp-37 {
    padding-right: 9.86667vw !important;
  }
  .pbsp-37 {
    padding-bottom: 9.86667vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-38 {
    margin-top: 38px !important;
  }
  .mlpc-38 {
    margin-left: 38px !important;
  }
  .mrpc-38 {
    margin-right: 38px !important;
  }
  .mbpc-38 {
    margin-bottom: 38px !important;
  }
  .ptpc-38 {
    padding-top: 38px !important;
  }
  .plpc-38 {
    padding-left: 38px !important;
  }
  .prpc-38 {
    padding-right: 38px !important;
  }
  .pbpc-38 {
    padding-bottom: 38px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-38 {
    margin-top: 10.13333vw !important;
  }
  .mlsp-38 {
    margin-left: 10.13333vw !important;
  }
  .mrsp-38 {
    margin-right: 10.13333vw !important;
  }
  .mbsp-38 {
    margin-bottom: 10.13333vw !important;
  }
  .ptsp-38 {
    padding-top: 10.13333vw !important;
  }
  .plsp-38 {
    padding-left: 10.13333vw !important;
  }
  .prsp-38 {
    padding-right: 10.13333vw !important;
  }
  .pbsp-38 {
    padding-bottom: 10.13333vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-39 {
    margin-top: 39px !important;
  }
  .mlpc-39 {
    margin-left: 39px !important;
  }
  .mrpc-39 {
    margin-right: 39px !important;
  }
  .mbpc-39 {
    margin-bottom: 39px !important;
  }
  .ptpc-39 {
    padding-top: 39px !important;
  }
  .plpc-39 {
    padding-left: 39px !important;
  }
  .prpc-39 {
    padding-right: 39px !important;
  }
  .pbpc-39 {
    padding-bottom: 39px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-39 {
    margin-top: 10.4vw !important;
  }
  .mlsp-39 {
    margin-left: 10.4vw !important;
  }
  .mrsp-39 {
    margin-right: 10.4vw !important;
  }
  .mbsp-39 {
    margin-bottom: 10.4vw !important;
  }
  .ptsp-39 {
    padding-top: 10.4vw !important;
  }
  .plsp-39 {
    padding-left: 10.4vw !important;
  }
  .prsp-39 {
    padding-right: 10.4vw !important;
  }
  .pbsp-39 {
    padding-bottom: 10.4vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-40 {
    margin-top: 40px !important;
  }
  .mlpc-40 {
    margin-left: 40px !important;
  }
  .mrpc-40 {
    margin-right: 40px !important;
  }
  .mbpc-40 {
    margin-bottom: 40px !important;
  }
  .ptpc-40 {
    padding-top: 40px !important;
  }
  .plpc-40 {
    padding-left: 40px !important;
  }
  .prpc-40 {
    padding-right: 40px !important;
  }
  .pbpc-40 {
    padding-bottom: 40px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-40 {
    margin-top: 10.66667vw !important;
  }
  .mlsp-40 {
    margin-left: 10.66667vw !important;
  }
  .mrsp-40 {
    margin-right: 10.66667vw !important;
  }
  .mbsp-40 {
    margin-bottom: 10.66667vw !important;
  }
  .ptsp-40 {
    padding-top: 10.66667vw !important;
  }
  .plsp-40 {
    padding-left: 10.66667vw !important;
  }
  .prsp-40 {
    padding-right: 10.66667vw !important;
  }
  .pbsp-40 {
    padding-bottom: 10.66667vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-41 {
    margin-top: 41px !important;
  }
  .mlpc-41 {
    margin-left: 41px !important;
  }
  .mrpc-41 {
    margin-right: 41px !important;
  }
  .mbpc-41 {
    margin-bottom: 41px !important;
  }
  .ptpc-41 {
    padding-top: 41px !important;
  }
  .plpc-41 {
    padding-left: 41px !important;
  }
  .prpc-41 {
    padding-right: 41px !important;
  }
  .pbpc-41 {
    padding-bottom: 41px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-41 {
    margin-top: 10.93333vw !important;
  }
  .mlsp-41 {
    margin-left: 10.93333vw !important;
  }
  .mrsp-41 {
    margin-right: 10.93333vw !important;
  }
  .mbsp-41 {
    margin-bottom: 10.93333vw !important;
  }
  .ptsp-41 {
    padding-top: 10.93333vw !important;
  }
  .plsp-41 {
    padding-left: 10.93333vw !important;
  }
  .prsp-41 {
    padding-right: 10.93333vw !important;
  }
  .pbsp-41 {
    padding-bottom: 10.93333vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-42 {
    margin-top: 42px !important;
  }
  .mlpc-42 {
    margin-left: 42px !important;
  }
  .mrpc-42 {
    margin-right: 42px !important;
  }
  .mbpc-42 {
    margin-bottom: 42px !important;
  }
  .ptpc-42 {
    padding-top: 42px !important;
  }
  .plpc-42 {
    padding-left: 42px !important;
  }
  .prpc-42 {
    padding-right: 42px !important;
  }
  .pbpc-42 {
    padding-bottom: 42px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-42 {
    margin-top: 11.2vw !important;
  }
  .mlsp-42 {
    margin-left: 11.2vw !important;
  }
  .mrsp-42 {
    margin-right: 11.2vw !important;
  }
  .mbsp-42 {
    margin-bottom: 11.2vw !important;
  }
  .ptsp-42 {
    padding-top: 11.2vw !important;
  }
  .plsp-42 {
    padding-left: 11.2vw !important;
  }
  .prsp-42 {
    padding-right: 11.2vw !important;
  }
  .pbsp-42 {
    padding-bottom: 11.2vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-43 {
    margin-top: 43px !important;
  }
  .mlpc-43 {
    margin-left: 43px !important;
  }
  .mrpc-43 {
    margin-right: 43px !important;
  }
  .mbpc-43 {
    margin-bottom: 43px !important;
  }
  .ptpc-43 {
    padding-top: 43px !important;
  }
  .plpc-43 {
    padding-left: 43px !important;
  }
  .prpc-43 {
    padding-right: 43px !important;
  }
  .pbpc-43 {
    padding-bottom: 43px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-43 {
    margin-top: 11.46667vw !important;
  }
  .mlsp-43 {
    margin-left: 11.46667vw !important;
  }
  .mrsp-43 {
    margin-right: 11.46667vw !important;
  }
  .mbsp-43 {
    margin-bottom: 11.46667vw !important;
  }
  .ptsp-43 {
    padding-top: 11.46667vw !important;
  }
  .plsp-43 {
    padding-left: 11.46667vw !important;
  }
  .prsp-43 {
    padding-right: 11.46667vw !important;
  }
  .pbsp-43 {
    padding-bottom: 11.46667vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-44 {
    margin-top: 44px !important;
  }
  .mlpc-44 {
    margin-left: 44px !important;
  }
  .mrpc-44 {
    margin-right: 44px !important;
  }
  .mbpc-44 {
    margin-bottom: 44px !important;
  }
  .ptpc-44 {
    padding-top: 44px !important;
  }
  .plpc-44 {
    padding-left: 44px !important;
  }
  .prpc-44 {
    padding-right: 44px !important;
  }
  .pbpc-44 {
    padding-bottom: 44px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-44 {
    margin-top: 11.73333vw !important;
  }
  .mlsp-44 {
    margin-left: 11.73333vw !important;
  }
  .mrsp-44 {
    margin-right: 11.73333vw !important;
  }
  .mbsp-44 {
    margin-bottom: 11.73333vw !important;
  }
  .ptsp-44 {
    padding-top: 11.73333vw !important;
  }
  .plsp-44 {
    padding-left: 11.73333vw !important;
  }
  .prsp-44 {
    padding-right: 11.73333vw !important;
  }
  .pbsp-44 {
    padding-bottom: 11.73333vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-45 {
    margin-top: 45px !important;
  }
  .mlpc-45 {
    margin-left: 45px !important;
  }
  .mrpc-45 {
    margin-right: 45px !important;
  }
  .mbpc-45 {
    margin-bottom: 45px !important;
  }
  .ptpc-45 {
    padding-top: 45px !important;
  }
  .plpc-45 {
    padding-left: 45px !important;
  }
  .prpc-45 {
    padding-right: 45px !important;
  }
  .pbpc-45 {
    padding-bottom: 45px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-45 {
    margin-top: 12vw !important;
  }
  .mlsp-45 {
    margin-left: 12vw !important;
  }
  .mrsp-45 {
    margin-right: 12vw !important;
  }
  .mbsp-45 {
    margin-bottom: 12vw !important;
  }
  .ptsp-45 {
    padding-top: 12vw !important;
  }
  .plsp-45 {
    padding-left: 12vw !important;
  }
  .prsp-45 {
    padding-right: 12vw !important;
  }
  .pbsp-45 {
    padding-bottom: 12vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-46 {
    margin-top: 46px !important;
  }
  .mlpc-46 {
    margin-left: 46px !important;
  }
  .mrpc-46 {
    margin-right: 46px !important;
  }
  .mbpc-46 {
    margin-bottom: 46px !important;
  }
  .ptpc-46 {
    padding-top: 46px !important;
  }
  .plpc-46 {
    padding-left: 46px !important;
  }
  .prpc-46 {
    padding-right: 46px !important;
  }
  .pbpc-46 {
    padding-bottom: 46px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-46 {
    margin-top: 12.26667vw !important;
  }
  .mlsp-46 {
    margin-left: 12.26667vw !important;
  }
  .mrsp-46 {
    margin-right: 12.26667vw !important;
  }
  .mbsp-46 {
    margin-bottom: 12.26667vw !important;
  }
  .ptsp-46 {
    padding-top: 12.26667vw !important;
  }
  .plsp-46 {
    padding-left: 12.26667vw !important;
  }
  .prsp-46 {
    padding-right: 12.26667vw !important;
  }
  .pbsp-46 {
    padding-bottom: 12.26667vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-47 {
    margin-top: 47px !important;
  }
  .mlpc-47 {
    margin-left: 47px !important;
  }
  .mrpc-47 {
    margin-right: 47px !important;
  }
  .mbpc-47 {
    margin-bottom: 47px !important;
  }
  .ptpc-47 {
    padding-top: 47px !important;
  }
  .plpc-47 {
    padding-left: 47px !important;
  }
  .prpc-47 {
    padding-right: 47px !important;
  }
  .pbpc-47 {
    padding-bottom: 47px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-47 {
    margin-top: 12.53333vw !important;
  }
  .mlsp-47 {
    margin-left: 12.53333vw !important;
  }
  .mrsp-47 {
    margin-right: 12.53333vw !important;
  }
  .mbsp-47 {
    margin-bottom: 12.53333vw !important;
  }
  .ptsp-47 {
    padding-top: 12.53333vw !important;
  }
  .plsp-47 {
    padding-left: 12.53333vw !important;
  }
  .prsp-47 {
    padding-right: 12.53333vw !important;
  }
  .pbsp-47 {
    padding-bottom: 12.53333vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-48 {
    margin-top: 48px !important;
  }
  .mlpc-48 {
    margin-left: 48px !important;
  }
  .mrpc-48 {
    margin-right: 48px !important;
  }
  .mbpc-48 {
    margin-bottom: 48px !important;
  }
  .ptpc-48 {
    padding-top: 48px !important;
  }
  .plpc-48 {
    padding-left: 48px !important;
  }
  .prpc-48 {
    padding-right: 48px !important;
  }
  .pbpc-48 {
    padding-bottom: 48px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-48 {
    margin-top: 12.8vw !important;
  }
  .mlsp-48 {
    margin-left: 12.8vw !important;
  }
  .mrsp-48 {
    margin-right: 12.8vw !important;
  }
  .mbsp-48 {
    margin-bottom: 12.8vw !important;
  }
  .ptsp-48 {
    padding-top: 12.8vw !important;
  }
  .plsp-48 {
    padding-left: 12.8vw !important;
  }
  .prsp-48 {
    padding-right: 12.8vw !important;
  }
  .pbsp-48 {
    padding-bottom: 12.8vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-49 {
    margin-top: 49px !important;
  }
  .mlpc-49 {
    margin-left: 49px !important;
  }
  .mrpc-49 {
    margin-right: 49px !important;
  }
  .mbpc-49 {
    margin-bottom: 49px !important;
  }
  .ptpc-49 {
    padding-top: 49px !important;
  }
  .plpc-49 {
    padding-left: 49px !important;
  }
  .prpc-49 {
    padding-right: 49px !important;
  }
  .pbpc-49 {
    padding-bottom: 49px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-49 {
    margin-top: 13.06667vw !important;
  }
  .mlsp-49 {
    margin-left: 13.06667vw !important;
  }
  .mrsp-49 {
    margin-right: 13.06667vw !important;
  }
  .mbsp-49 {
    margin-bottom: 13.06667vw !important;
  }
  .ptsp-49 {
    padding-top: 13.06667vw !important;
  }
  .plsp-49 {
    padding-left: 13.06667vw !important;
  }
  .prsp-49 {
    padding-right: 13.06667vw !important;
  }
  .pbsp-49 {
    padding-bottom: 13.06667vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-50 {
    margin-top: 50px !important;
  }
  .mlpc-50 {
    margin-left: 50px !important;
  }
  .mrpc-50 {
    margin-right: 50px !important;
  }
  .mbpc-50 {
    margin-bottom: 50px !important;
  }
  .ptpc-50 {
    padding-top: 50px !important;
  }
  .plpc-50 {
    padding-left: 50px !important;
  }
  .prpc-50 {
    padding-right: 50px !important;
  }
  .pbpc-50 {
    padding-bottom: 50px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-50 {
    margin-top: 13.33333vw !important;
  }
  .mlsp-50 {
    margin-left: 13.33333vw !important;
  }
  .mrsp-50 {
    margin-right: 13.33333vw !important;
  }
  .mbsp-50 {
    margin-bottom: 13.33333vw !important;
  }
  .ptsp-50 {
    padding-top: 13.33333vw !important;
  }
  .plsp-50 {
    padding-left: 13.33333vw !important;
  }
  .prsp-50 {
    padding-right: 13.33333vw !important;
  }
  .pbsp-50 {
    padding-bottom: 13.33333vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-51 {
    margin-top: 51px !important;
  }
  .mlpc-51 {
    margin-left: 51px !important;
  }
  .mrpc-51 {
    margin-right: 51px !important;
  }
  .mbpc-51 {
    margin-bottom: 51px !important;
  }
  .ptpc-51 {
    padding-top: 51px !important;
  }
  .plpc-51 {
    padding-left: 51px !important;
  }
  .prpc-51 {
    padding-right: 51px !important;
  }
  .pbpc-51 {
    padding-bottom: 51px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-51 {
    margin-top: 13.6vw !important;
  }
  .mlsp-51 {
    margin-left: 13.6vw !important;
  }
  .mrsp-51 {
    margin-right: 13.6vw !important;
  }
  .mbsp-51 {
    margin-bottom: 13.6vw !important;
  }
  .ptsp-51 {
    padding-top: 13.6vw !important;
  }
  .plsp-51 {
    padding-left: 13.6vw !important;
  }
  .prsp-51 {
    padding-right: 13.6vw !important;
  }
  .pbsp-51 {
    padding-bottom: 13.6vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-52 {
    margin-top: 52px !important;
  }
  .mlpc-52 {
    margin-left: 52px !important;
  }
  .mrpc-52 {
    margin-right: 52px !important;
  }
  .mbpc-52 {
    margin-bottom: 52px !important;
  }
  .ptpc-52 {
    padding-top: 52px !important;
  }
  .plpc-52 {
    padding-left: 52px !important;
  }
  .prpc-52 {
    padding-right: 52px !important;
  }
  .pbpc-52 {
    padding-bottom: 52px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-52 {
    margin-top: 13.86667vw !important;
  }
  .mlsp-52 {
    margin-left: 13.86667vw !important;
  }
  .mrsp-52 {
    margin-right: 13.86667vw !important;
  }
  .mbsp-52 {
    margin-bottom: 13.86667vw !important;
  }
  .ptsp-52 {
    padding-top: 13.86667vw !important;
  }
  .plsp-52 {
    padding-left: 13.86667vw !important;
  }
  .prsp-52 {
    padding-right: 13.86667vw !important;
  }
  .pbsp-52 {
    padding-bottom: 13.86667vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-53 {
    margin-top: 53px !important;
  }
  .mlpc-53 {
    margin-left: 53px !important;
  }
  .mrpc-53 {
    margin-right: 53px !important;
  }
  .mbpc-53 {
    margin-bottom: 53px !important;
  }
  .ptpc-53 {
    padding-top: 53px !important;
  }
  .plpc-53 {
    padding-left: 53px !important;
  }
  .prpc-53 {
    padding-right: 53px !important;
  }
  .pbpc-53 {
    padding-bottom: 53px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-53 {
    margin-top: 14.13333vw !important;
  }
  .mlsp-53 {
    margin-left: 14.13333vw !important;
  }
  .mrsp-53 {
    margin-right: 14.13333vw !important;
  }
  .mbsp-53 {
    margin-bottom: 14.13333vw !important;
  }
  .ptsp-53 {
    padding-top: 14.13333vw !important;
  }
  .plsp-53 {
    padding-left: 14.13333vw !important;
  }
  .prsp-53 {
    padding-right: 14.13333vw !important;
  }
  .pbsp-53 {
    padding-bottom: 14.13333vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-54 {
    margin-top: 54px !important;
  }
  .mlpc-54 {
    margin-left: 54px !important;
  }
  .mrpc-54 {
    margin-right: 54px !important;
  }
  .mbpc-54 {
    margin-bottom: 54px !important;
  }
  .ptpc-54 {
    padding-top: 54px !important;
  }
  .plpc-54 {
    padding-left: 54px !important;
  }
  .prpc-54 {
    padding-right: 54px !important;
  }
  .pbpc-54 {
    padding-bottom: 54px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-54 {
    margin-top: 14.4vw !important;
  }
  .mlsp-54 {
    margin-left: 14.4vw !important;
  }
  .mrsp-54 {
    margin-right: 14.4vw !important;
  }
  .mbsp-54 {
    margin-bottom: 14.4vw !important;
  }
  .ptsp-54 {
    padding-top: 14.4vw !important;
  }
  .plsp-54 {
    padding-left: 14.4vw !important;
  }
  .prsp-54 {
    padding-right: 14.4vw !important;
  }
  .pbsp-54 {
    padding-bottom: 14.4vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-55 {
    margin-top: 55px !important;
  }
  .mlpc-55 {
    margin-left: 55px !important;
  }
  .mrpc-55 {
    margin-right: 55px !important;
  }
  .mbpc-55 {
    margin-bottom: 55px !important;
  }
  .ptpc-55 {
    padding-top: 55px !important;
  }
  .plpc-55 {
    padding-left: 55px !important;
  }
  .prpc-55 {
    padding-right: 55px !important;
  }
  .pbpc-55 {
    padding-bottom: 55px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-55 {
    margin-top: 14.66667vw !important;
  }
  .mlsp-55 {
    margin-left: 14.66667vw !important;
  }
  .mrsp-55 {
    margin-right: 14.66667vw !important;
  }
  .mbsp-55 {
    margin-bottom: 14.66667vw !important;
  }
  .ptsp-55 {
    padding-top: 14.66667vw !important;
  }
  .plsp-55 {
    padding-left: 14.66667vw !important;
  }
  .prsp-55 {
    padding-right: 14.66667vw !important;
  }
  .pbsp-55 {
    padding-bottom: 14.66667vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-56 {
    margin-top: 56px !important;
  }
  .mlpc-56 {
    margin-left: 56px !important;
  }
  .mrpc-56 {
    margin-right: 56px !important;
  }
  .mbpc-56 {
    margin-bottom: 56px !important;
  }
  .ptpc-56 {
    padding-top: 56px !important;
  }
  .plpc-56 {
    padding-left: 56px !important;
  }
  .prpc-56 {
    padding-right: 56px !important;
  }
  .pbpc-56 {
    padding-bottom: 56px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-56 {
    margin-top: 14.93333vw !important;
  }
  .mlsp-56 {
    margin-left: 14.93333vw !important;
  }
  .mrsp-56 {
    margin-right: 14.93333vw !important;
  }
  .mbsp-56 {
    margin-bottom: 14.93333vw !important;
  }
  .ptsp-56 {
    padding-top: 14.93333vw !important;
  }
  .plsp-56 {
    padding-left: 14.93333vw !important;
  }
  .prsp-56 {
    padding-right: 14.93333vw !important;
  }
  .pbsp-56 {
    padding-bottom: 14.93333vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-57 {
    margin-top: 57px !important;
  }
  .mlpc-57 {
    margin-left: 57px !important;
  }
  .mrpc-57 {
    margin-right: 57px !important;
  }
  .mbpc-57 {
    margin-bottom: 57px !important;
  }
  .ptpc-57 {
    padding-top: 57px !important;
  }
  .plpc-57 {
    padding-left: 57px !important;
  }
  .prpc-57 {
    padding-right: 57px !important;
  }
  .pbpc-57 {
    padding-bottom: 57px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-57 {
    margin-top: 15.2vw !important;
  }
  .mlsp-57 {
    margin-left: 15.2vw !important;
  }
  .mrsp-57 {
    margin-right: 15.2vw !important;
  }
  .mbsp-57 {
    margin-bottom: 15.2vw !important;
  }
  .ptsp-57 {
    padding-top: 15.2vw !important;
  }
  .plsp-57 {
    padding-left: 15.2vw !important;
  }
  .prsp-57 {
    padding-right: 15.2vw !important;
  }
  .pbsp-57 {
    padding-bottom: 15.2vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-58 {
    margin-top: 58px !important;
  }
  .mlpc-58 {
    margin-left: 58px !important;
  }
  .mrpc-58 {
    margin-right: 58px !important;
  }
  .mbpc-58 {
    margin-bottom: 58px !important;
  }
  .ptpc-58 {
    padding-top: 58px !important;
  }
  .plpc-58 {
    padding-left: 58px !important;
  }
  .prpc-58 {
    padding-right: 58px !important;
  }
  .pbpc-58 {
    padding-bottom: 58px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-58 {
    margin-top: 15.46667vw !important;
  }
  .mlsp-58 {
    margin-left: 15.46667vw !important;
  }
  .mrsp-58 {
    margin-right: 15.46667vw !important;
  }
  .mbsp-58 {
    margin-bottom: 15.46667vw !important;
  }
  .ptsp-58 {
    padding-top: 15.46667vw !important;
  }
  .plsp-58 {
    padding-left: 15.46667vw !important;
  }
  .prsp-58 {
    padding-right: 15.46667vw !important;
  }
  .pbsp-58 {
    padding-bottom: 15.46667vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-59 {
    margin-top: 59px !important;
  }
  .mlpc-59 {
    margin-left: 59px !important;
  }
  .mrpc-59 {
    margin-right: 59px !important;
  }
  .mbpc-59 {
    margin-bottom: 59px !important;
  }
  .ptpc-59 {
    padding-top: 59px !important;
  }
  .plpc-59 {
    padding-left: 59px !important;
  }
  .prpc-59 {
    padding-right: 59px !important;
  }
  .pbpc-59 {
    padding-bottom: 59px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-59 {
    margin-top: 15.73333vw !important;
  }
  .mlsp-59 {
    margin-left: 15.73333vw !important;
  }
  .mrsp-59 {
    margin-right: 15.73333vw !important;
  }
  .mbsp-59 {
    margin-bottom: 15.73333vw !important;
  }
  .ptsp-59 {
    padding-top: 15.73333vw !important;
  }
  .plsp-59 {
    padding-left: 15.73333vw !important;
  }
  .prsp-59 {
    padding-right: 15.73333vw !important;
  }
  .pbsp-59 {
    padding-bottom: 15.73333vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-60 {
    margin-top: 60px !important;
  }
  .mlpc-60 {
    margin-left: 60px !important;
  }
  .mrpc-60 {
    margin-right: 60px !important;
  }
  .mbpc-60 {
    margin-bottom: 60px !important;
  }
  .ptpc-60 {
    padding-top: 60px !important;
  }
  .plpc-60 {
    padding-left: 60px !important;
  }
  .prpc-60 {
    padding-right: 60px !important;
  }
  .pbpc-60 {
    padding-bottom: 60px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-60 {
    margin-top: 16vw !important;
  }
  .mlsp-60 {
    margin-left: 16vw !important;
  }
  .mrsp-60 {
    margin-right: 16vw !important;
  }
  .mbsp-60 {
    margin-bottom: 16vw !important;
  }
  .ptsp-60 {
    padding-top: 16vw !important;
  }
  .plsp-60 {
    padding-left: 16vw !important;
  }
  .prsp-60 {
    padding-right: 16vw !important;
  }
  .pbsp-60 {
    padding-bottom: 16vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-61 {
    margin-top: 61px !important;
  }
  .mlpc-61 {
    margin-left: 61px !important;
  }
  .mrpc-61 {
    margin-right: 61px !important;
  }
  .mbpc-61 {
    margin-bottom: 61px !important;
  }
  .ptpc-61 {
    padding-top: 61px !important;
  }
  .plpc-61 {
    padding-left: 61px !important;
  }
  .prpc-61 {
    padding-right: 61px !important;
  }
  .pbpc-61 {
    padding-bottom: 61px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-61 {
    margin-top: 16.26667vw !important;
  }
  .mlsp-61 {
    margin-left: 16.26667vw !important;
  }
  .mrsp-61 {
    margin-right: 16.26667vw !important;
  }
  .mbsp-61 {
    margin-bottom: 16.26667vw !important;
  }
  .ptsp-61 {
    padding-top: 16.26667vw !important;
  }
  .plsp-61 {
    padding-left: 16.26667vw !important;
  }
  .prsp-61 {
    padding-right: 16.26667vw !important;
  }
  .pbsp-61 {
    padding-bottom: 16.26667vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-62 {
    margin-top: 62px !important;
  }
  .mlpc-62 {
    margin-left: 62px !important;
  }
  .mrpc-62 {
    margin-right: 62px !important;
  }
  .mbpc-62 {
    margin-bottom: 62px !important;
  }
  .ptpc-62 {
    padding-top: 62px !important;
  }
  .plpc-62 {
    padding-left: 62px !important;
  }
  .prpc-62 {
    padding-right: 62px !important;
  }
  .pbpc-62 {
    padding-bottom: 62px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-62 {
    margin-top: 16.53333vw !important;
  }
  .mlsp-62 {
    margin-left: 16.53333vw !important;
  }
  .mrsp-62 {
    margin-right: 16.53333vw !important;
  }
  .mbsp-62 {
    margin-bottom: 16.53333vw !important;
  }
  .ptsp-62 {
    padding-top: 16.53333vw !important;
  }
  .plsp-62 {
    padding-left: 16.53333vw !important;
  }
  .prsp-62 {
    padding-right: 16.53333vw !important;
  }
  .pbsp-62 {
    padding-bottom: 16.53333vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-63 {
    margin-top: 63px !important;
  }
  .mlpc-63 {
    margin-left: 63px !important;
  }
  .mrpc-63 {
    margin-right: 63px !important;
  }
  .mbpc-63 {
    margin-bottom: 63px !important;
  }
  .ptpc-63 {
    padding-top: 63px !important;
  }
  .plpc-63 {
    padding-left: 63px !important;
  }
  .prpc-63 {
    padding-right: 63px !important;
  }
  .pbpc-63 {
    padding-bottom: 63px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-63 {
    margin-top: 16.8vw !important;
  }
  .mlsp-63 {
    margin-left: 16.8vw !important;
  }
  .mrsp-63 {
    margin-right: 16.8vw !important;
  }
  .mbsp-63 {
    margin-bottom: 16.8vw !important;
  }
  .ptsp-63 {
    padding-top: 16.8vw !important;
  }
  .plsp-63 {
    padding-left: 16.8vw !important;
  }
  .prsp-63 {
    padding-right: 16.8vw !important;
  }
  .pbsp-63 {
    padding-bottom: 16.8vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-64 {
    margin-top: 64px !important;
  }
  .mlpc-64 {
    margin-left: 64px !important;
  }
  .mrpc-64 {
    margin-right: 64px !important;
  }
  .mbpc-64 {
    margin-bottom: 64px !important;
  }
  .ptpc-64 {
    padding-top: 64px !important;
  }
  .plpc-64 {
    padding-left: 64px !important;
  }
  .prpc-64 {
    padding-right: 64px !important;
  }
  .pbpc-64 {
    padding-bottom: 64px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-64 {
    margin-top: 17.06667vw !important;
  }
  .mlsp-64 {
    margin-left: 17.06667vw !important;
  }
  .mrsp-64 {
    margin-right: 17.06667vw !important;
  }
  .mbsp-64 {
    margin-bottom: 17.06667vw !important;
  }
  .ptsp-64 {
    padding-top: 17.06667vw !important;
  }
  .plsp-64 {
    padding-left: 17.06667vw !important;
  }
  .prsp-64 {
    padding-right: 17.06667vw !important;
  }
  .pbsp-64 {
    padding-bottom: 17.06667vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-65 {
    margin-top: 65px !important;
  }
  .mlpc-65 {
    margin-left: 65px !important;
  }
  .mrpc-65 {
    margin-right: 65px !important;
  }
  .mbpc-65 {
    margin-bottom: 65px !important;
  }
  .ptpc-65 {
    padding-top: 65px !important;
  }
  .plpc-65 {
    padding-left: 65px !important;
  }
  .prpc-65 {
    padding-right: 65px !important;
  }
  .pbpc-65 {
    padding-bottom: 65px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-65 {
    margin-top: 17.33333vw !important;
  }
  .mlsp-65 {
    margin-left: 17.33333vw !important;
  }
  .mrsp-65 {
    margin-right: 17.33333vw !important;
  }
  .mbsp-65 {
    margin-bottom: 17.33333vw !important;
  }
  .ptsp-65 {
    padding-top: 17.33333vw !important;
  }
  .plsp-65 {
    padding-left: 17.33333vw !important;
  }
  .prsp-65 {
    padding-right: 17.33333vw !important;
  }
  .pbsp-65 {
    padding-bottom: 17.33333vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-66 {
    margin-top: 66px !important;
  }
  .mlpc-66 {
    margin-left: 66px !important;
  }
  .mrpc-66 {
    margin-right: 66px !important;
  }
  .mbpc-66 {
    margin-bottom: 66px !important;
  }
  .ptpc-66 {
    padding-top: 66px !important;
  }
  .plpc-66 {
    padding-left: 66px !important;
  }
  .prpc-66 {
    padding-right: 66px !important;
  }
  .pbpc-66 {
    padding-bottom: 66px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-66 {
    margin-top: 17.6vw !important;
  }
  .mlsp-66 {
    margin-left: 17.6vw !important;
  }
  .mrsp-66 {
    margin-right: 17.6vw !important;
  }
  .mbsp-66 {
    margin-bottom: 17.6vw !important;
  }
  .ptsp-66 {
    padding-top: 17.6vw !important;
  }
  .plsp-66 {
    padding-left: 17.6vw !important;
  }
  .prsp-66 {
    padding-right: 17.6vw !important;
  }
  .pbsp-66 {
    padding-bottom: 17.6vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-67 {
    margin-top: 67px !important;
  }
  .mlpc-67 {
    margin-left: 67px !important;
  }
  .mrpc-67 {
    margin-right: 67px !important;
  }
  .mbpc-67 {
    margin-bottom: 67px !important;
  }
  .ptpc-67 {
    padding-top: 67px !important;
  }
  .plpc-67 {
    padding-left: 67px !important;
  }
  .prpc-67 {
    padding-right: 67px !important;
  }
  .pbpc-67 {
    padding-bottom: 67px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-67 {
    margin-top: 17.86667vw !important;
  }
  .mlsp-67 {
    margin-left: 17.86667vw !important;
  }
  .mrsp-67 {
    margin-right: 17.86667vw !important;
  }
  .mbsp-67 {
    margin-bottom: 17.86667vw !important;
  }
  .ptsp-67 {
    padding-top: 17.86667vw !important;
  }
  .plsp-67 {
    padding-left: 17.86667vw !important;
  }
  .prsp-67 {
    padding-right: 17.86667vw !important;
  }
  .pbsp-67 {
    padding-bottom: 17.86667vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-68 {
    margin-top: 68px !important;
  }
  .mlpc-68 {
    margin-left: 68px !important;
  }
  .mrpc-68 {
    margin-right: 68px !important;
  }
  .mbpc-68 {
    margin-bottom: 68px !important;
  }
  .ptpc-68 {
    padding-top: 68px !important;
  }
  .plpc-68 {
    padding-left: 68px !important;
  }
  .prpc-68 {
    padding-right: 68px !important;
  }
  .pbpc-68 {
    padding-bottom: 68px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-68 {
    margin-top: 18.13333vw !important;
  }
  .mlsp-68 {
    margin-left: 18.13333vw !important;
  }
  .mrsp-68 {
    margin-right: 18.13333vw !important;
  }
  .mbsp-68 {
    margin-bottom: 18.13333vw !important;
  }
  .ptsp-68 {
    padding-top: 18.13333vw !important;
  }
  .plsp-68 {
    padding-left: 18.13333vw !important;
  }
  .prsp-68 {
    padding-right: 18.13333vw !important;
  }
  .pbsp-68 {
    padding-bottom: 18.13333vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-69 {
    margin-top: 69px !important;
  }
  .mlpc-69 {
    margin-left: 69px !important;
  }
  .mrpc-69 {
    margin-right: 69px !important;
  }
  .mbpc-69 {
    margin-bottom: 69px !important;
  }
  .ptpc-69 {
    padding-top: 69px !important;
  }
  .plpc-69 {
    padding-left: 69px !important;
  }
  .prpc-69 {
    padding-right: 69px !important;
  }
  .pbpc-69 {
    padding-bottom: 69px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-69 {
    margin-top: 18.4vw !important;
  }
  .mlsp-69 {
    margin-left: 18.4vw !important;
  }
  .mrsp-69 {
    margin-right: 18.4vw !important;
  }
  .mbsp-69 {
    margin-bottom: 18.4vw !important;
  }
  .ptsp-69 {
    padding-top: 18.4vw !important;
  }
  .plsp-69 {
    padding-left: 18.4vw !important;
  }
  .prsp-69 {
    padding-right: 18.4vw !important;
  }
  .pbsp-69 {
    padding-bottom: 18.4vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-70 {
    margin-top: 70px !important;
  }
  .mlpc-70 {
    margin-left: 70px !important;
  }
  .mrpc-70 {
    margin-right: 70px !important;
  }
  .mbpc-70 {
    margin-bottom: 70px !important;
  }
  .ptpc-70 {
    padding-top: 70px !important;
  }
  .plpc-70 {
    padding-left: 70px !important;
  }
  .prpc-70 {
    padding-right: 70px !important;
  }
  .pbpc-70 {
    padding-bottom: 70px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-70 {
    margin-top: 18.66667vw !important;
  }
  .mlsp-70 {
    margin-left: 18.66667vw !important;
  }
  .mrsp-70 {
    margin-right: 18.66667vw !important;
  }
  .mbsp-70 {
    margin-bottom: 18.66667vw !important;
  }
  .ptsp-70 {
    padding-top: 18.66667vw !important;
  }
  .plsp-70 {
    padding-left: 18.66667vw !important;
  }
  .prsp-70 {
    padding-right: 18.66667vw !important;
  }
  .pbsp-70 {
    padding-bottom: 18.66667vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-71 {
    margin-top: 71px !important;
  }
  .mlpc-71 {
    margin-left: 71px !important;
  }
  .mrpc-71 {
    margin-right: 71px !important;
  }
  .mbpc-71 {
    margin-bottom: 71px !important;
  }
  .ptpc-71 {
    padding-top: 71px !important;
  }
  .plpc-71 {
    padding-left: 71px !important;
  }
  .prpc-71 {
    padding-right: 71px !important;
  }
  .pbpc-71 {
    padding-bottom: 71px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-71 {
    margin-top: 18.93333vw !important;
  }
  .mlsp-71 {
    margin-left: 18.93333vw !important;
  }
  .mrsp-71 {
    margin-right: 18.93333vw !important;
  }
  .mbsp-71 {
    margin-bottom: 18.93333vw !important;
  }
  .ptsp-71 {
    padding-top: 18.93333vw !important;
  }
  .plsp-71 {
    padding-left: 18.93333vw !important;
  }
  .prsp-71 {
    padding-right: 18.93333vw !important;
  }
  .pbsp-71 {
    padding-bottom: 18.93333vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-72 {
    margin-top: 72px !important;
  }
  .mlpc-72 {
    margin-left: 72px !important;
  }
  .mrpc-72 {
    margin-right: 72px !important;
  }
  .mbpc-72 {
    margin-bottom: 72px !important;
  }
  .ptpc-72 {
    padding-top: 72px !important;
  }
  .plpc-72 {
    padding-left: 72px !important;
  }
  .prpc-72 {
    padding-right: 72px !important;
  }
  .pbpc-72 {
    padding-bottom: 72px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-72 {
    margin-top: 19.2vw !important;
  }
  .mlsp-72 {
    margin-left: 19.2vw !important;
  }
  .mrsp-72 {
    margin-right: 19.2vw !important;
  }
  .mbsp-72 {
    margin-bottom: 19.2vw !important;
  }
  .ptsp-72 {
    padding-top: 19.2vw !important;
  }
  .plsp-72 {
    padding-left: 19.2vw !important;
  }
  .prsp-72 {
    padding-right: 19.2vw !important;
  }
  .pbsp-72 {
    padding-bottom: 19.2vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-73 {
    margin-top: 73px !important;
  }
  .mlpc-73 {
    margin-left: 73px !important;
  }
  .mrpc-73 {
    margin-right: 73px !important;
  }
  .mbpc-73 {
    margin-bottom: 73px !important;
  }
  .ptpc-73 {
    padding-top: 73px !important;
  }
  .plpc-73 {
    padding-left: 73px !important;
  }
  .prpc-73 {
    padding-right: 73px !important;
  }
  .pbpc-73 {
    padding-bottom: 73px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-73 {
    margin-top: 19.46667vw !important;
  }
  .mlsp-73 {
    margin-left: 19.46667vw !important;
  }
  .mrsp-73 {
    margin-right: 19.46667vw !important;
  }
  .mbsp-73 {
    margin-bottom: 19.46667vw !important;
  }
  .ptsp-73 {
    padding-top: 19.46667vw !important;
  }
  .plsp-73 {
    padding-left: 19.46667vw !important;
  }
  .prsp-73 {
    padding-right: 19.46667vw !important;
  }
  .pbsp-73 {
    padding-bottom: 19.46667vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-74 {
    margin-top: 74px !important;
  }
  .mlpc-74 {
    margin-left: 74px !important;
  }
  .mrpc-74 {
    margin-right: 74px !important;
  }
  .mbpc-74 {
    margin-bottom: 74px !important;
  }
  .ptpc-74 {
    padding-top: 74px !important;
  }
  .plpc-74 {
    padding-left: 74px !important;
  }
  .prpc-74 {
    padding-right: 74px !important;
  }
  .pbpc-74 {
    padding-bottom: 74px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-74 {
    margin-top: 19.73333vw !important;
  }
  .mlsp-74 {
    margin-left: 19.73333vw !important;
  }
  .mrsp-74 {
    margin-right: 19.73333vw !important;
  }
  .mbsp-74 {
    margin-bottom: 19.73333vw !important;
  }
  .ptsp-74 {
    padding-top: 19.73333vw !important;
  }
  .plsp-74 {
    padding-left: 19.73333vw !important;
  }
  .prsp-74 {
    padding-right: 19.73333vw !important;
  }
  .pbsp-74 {
    padding-bottom: 19.73333vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-75 {
    margin-top: 75px !important;
  }
  .mlpc-75 {
    margin-left: 75px !important;
  }
  .mrpc-75 {
    margin-right: 75px !important;
  }
  .mbpc-75 {
    margin-bottom: 75px !important;
  }
  .ptpc-75 {
    padding-top: 75px !important;
  }
  .plpc-75 {
    padding-left: 75px !important;
  }
  .prpc-75 {
    padding-right: 75px !important;
  }
  .pbpc-75 {
    padding-bottom: 75px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-75 {
    margin-top: 20vw !important;
  }
  .mlsp-75 {
    margin-left: 20vw !important;
  }
  .mrsp-75 {
    margin-right: 20vw !important;
  }
  .mbsp-75 {
    margin-bottom: 20vw !important;
  }
  .ptsp-75 {
    padding-top: 20vw !important;
  }
  .plsp-75 {
    padding-left: 20vw !important;
  }
  .prsp-75 {
    padding-right: 20vw !important;
  }
  .pbsp-75 {
    padding-bottom: 20vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-76 {
    margin-top: 76px !important;
  }
  .mlpc-76 {
    margin-left: 76px !important;
  }
  .mrpc-76 {
    margin-right: 76px !important;
  }
  .mbpc-76 {
    margin-bottom: 76px !important;
  }
  .ptpc-76 {
    padding-top: 76px !important;
  }
  .plpc-76 {
    padding-left: 76px !important;
  }
  .prpc-76 {
    padding-right: 76px !important;
  }
  .pbpc-76 {
    padding-bottom: 76px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-76 {
    margin-top: 20.26667vw !important;
  }
  .mlsp-76 {
    margin-left: 20.26667vw !important;
  }
  .mrsp-76 {
    margin-right: 20.26667vw !important;
  }
  .mbsp-76 {
    margin-bottom: 20.26667vw !important;
  }
  .ptsp-76 {
    padding-top: 20.26667vw !important;
  }
  .plsp-76 {
    padding-left: 20.26667vw !important;
  }
  .prsp-76 {
    padding-right: 20.26667vw !important;
  }
  .pbsp-76 {
    padding-bottom: 20.26667vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-77 {
    margin-top: 77px !important;
  }
  .mlpc-77 {
    margin-left: 77px !important;
  }
  .mrpc-77 {
    margin-right: 77px !important;
  }
  .mbpc-77 {
    margin-bottom: 77px !important;
  }
  .ptpc-77 {
    padding-top: 77px !important;
  }
  .plpc-77 {
    padding-left: 77px !important;
  }
  .prpc-77 {
    padding-right: 77px !important;
  }
  .pbpc-77 {
    padding-bottom: 77px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-77 {
    margin-top: 20.53333vw !important;
  }
  .mlsp-77 {
    margin-left: 20.53333vw !important;
  }
  .mrsp-77 {
    margin-right: 20.53333vw !important;
  }
  .mbsp-77 {
    margin-bottom: 20.53333vw !important;
  }
  .ptsp-77 {
    padding-top: 20.53333vw !important;
  }
  .plsp-77 {
    padding-left: 20.53333vw !important;
  }
  .prsp-77 {
    padding-right: 20.53333vw !important;
  }
  .pbsp-77 {
    padding-bottom: 20.53333vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-78 {
    margin-top: 78px !important;
  }
  .mlpc-78 {
    margin-left: 78px !important;
  }
  .mrpc-78 {
    margin-right: 78px !important;
  }
  .mbpc-78 {
    margin-bottom: 78px !important;
  }
  .ptpc-78 {
    padding-top: 78px !important;
  }
  .plpc-78 {
    padding-left: 78px !important;
  }
  .prpc-78 {
    padding-right: 78px !important;
  }
  .pbpc-78 {
    padding-bottom: 78px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-78 {
    margin-top: 20.8vw !important;
  }
  .mlsp-78 {
    margin-left: 20.8vw !important;
  }
  .mrsp-78 {
    margin-right: 20.8vw !important;
  }
  .mbsp-78 {
    margin-bottom: 20.8vw !important;
  }
  .ptsp-78 {
    padding-top: 20.8vw !important;
  }
  .plsp-78 {
    padding-left: 20.8vw !important;
  }
  .prsp-78 {
    padding-right: 20.8vw !important;
  }
  .pbsp-78 {
    padding-bottom: 20.8vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-79 {
    margin-top: 79px !important;
  }
  .mlpc-79 {
    margin-left: 79px !important;
  }
  .mrpc-79 {
    margin-right: 79px !important;
  }
  .mbpc-79 {
    margin-bottom: 79px !important;
  }
  .ptpc-79 {
    padding-top: 79px !important;
  }
  .plpc-79 {
    padding-left: 79px !important;
  }
  .prpc-79 {
    padding-right: 79px !important;
  }
  .pbpc-79 {
    padding-bottom: 79px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-79 {
    margin-top: 21.06667vw !important;
  }
  .mlsp-79 {
    margin-left: 21.06667vw !important;
  }
  .mrsp-79 {
    margin-right: 21.06667vw !important;
  }
  .mbsp-79 {
    margin-bottom: 21.06667vw !important;
  }
  .ptsp-79 {
    padding-top: 21.06667vw !important;
  }
  .plsp-79 {
    padding-left: 21.06667vw !important;
  }
  .prsp-79 {
    padding-right: 21.06667vw !important;
  }
  .pbsp-79 {
    padding-bottom: 21.06667vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-80 {
    margin-top: 80px !important;
  }
  .mlpc-80 {
    margin-left: 80px !important;
  }
  .mrpc-80 {
    margin-right: 80px !important;
  }
  .mbpc-80 {
    margin-bottom: 80px !important;
  }
  .ptpc-80 {
    padding-top: 80px !important;
  }
  .plpc-80 {
    padding-left: 80px !important;
  }
  .prpc-80 {
    padding-right: 80px !important;
  }
  .pbpc-80 {
    padding-bottom: 80px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-80 {
    margin-top: 21.33333vw !important;
  }
  .mlsp-80 {
    margin-left: 21.33333vw !important;
  }
  .mrsp-80 {
    margin-right: 21.33333vw !important;
  }
  .mbsp-80 {
    margin-bottom: 21.33333vw !important;
  }
  .ptsp-80 {
    padding-top: 21.33333vw !important;
  }
  .plsp-80 {
    padding-left: 21.33333vw !important;
  }
  .prsp-80 {
    padding-right: 21.33333vw !important;
  }
  .pbsp-80 {
    padding-bottom: 21.33333vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-81 {
    margin-top: 81px !important;
  }
  .mlpc-81 {
    margin-left: 81px !important;
  }
  .mrpc-81 {
    margin-right: 81px !important;
  }
  .mbpc-81 {
    margin-bottom: 81px !important;
  }
  .ptpc-81 {
    padding-top: 81px !important;
  }
  .plpc-81 {
    padding-left: 81px !important;
  }
  .prpc-81 {
    padding-right: 81px !important;
  }
  .pbpc-81 {
    padding-bottom: 81px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-81 {
    margin-top: 21.6vw !important;
  }
  .mlsp-81 {
    margin-left: 21.6vw !important;
  }
  .mrsp-81 {
    margin-right: 21.6vw !important;
  }
  .mbsp-81 {
    margin-bottom: 21.6vw !important;
  }
  .ptsp-81 {
    padding-top: 21.6vw !important;
  }
  .plsp-81 {
    padding-left: 21.6vw !important;
  }
  .prsp-81 {
    padding-right: 21.6vw !important;
  }
  .pbsp-81 {
    padding-bottom: 21.6vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-82 {
    margin-top: 82px !important;
  }
  .mlpc-82 {
    margin-left: 82px !important;
  }
  .mrpc-82 {
    margin-right: 82px !important;
  }
  .mbpc-82 {
    margin-bottom: 82px !important;
  }
  .ptpc-82 {
    padding-top: 82px !important;
  }
  .plpc-82 {
    padding-left: 82px !important;
  }
  .prpc-82 {
    padding-right: 82px !important;
  }
  .pbpc-82 {
    padding-bottom: 82px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-82 {
    margin-top: 21.86667vw !important;
  }
  .mlsp-82 {
    margin-left: 21.86667vw !important;
  }
  .mrsp-82 {
    margin-right: 21.86667vw !important;
  }
  .mbsp-82 {
    margin-bottom: 21.86667vw !important;
  }
  .ptsp-82 {
    padding-top: 21.86667vw !important;
  }
  .plsp-82 {
    padding-left: 21.86667vw !important;
  }
  .prsp-82 {
    padding-right: 21.86667vw !important;
  }
  .pbsp-82 {
    padding-bottom: 21.86667vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-83 {
    margin-top: 83px !important;
  }
  .mlpc-83 {
    margin-left: 83px !important;
  }
  .mrpc-83 {
    margin-right: 83px !important;
  }
  .mbpc-83 {
    margin-bottom: 83px !important;
  }
  .ptpc-83 {
    padding-top: 83px !important;
  }
  .plpc-83 {
    padding-left: 83px !important;
  }
  .prpc-83 {
    padding-right: 83px !important;
  }
  .pbpc-83 {
    padding-bottom: 83px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-83 {
    margin-top: 22.13333vw !important;
  }
  .mlsp-83 {
    margin-left: 22.13333vw !important;
  }
  .mrsp-83 {
    margin-right: 22.13333vw !important;
  }
  .mbsp-83 {
    margin-bottom: 22.13333vw !important;
  }
  .ptsp-83 {
    padding-top: 22.13333vw !important;
  }
  .plsp-83 {
    padding-left: 22.13333vw !important;
  }
  .prsp-83 {
    padding-right: 22.13333vw !important;
  }
  .pbsp-83 {
    padding-bottom: 22.13333vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-84 {
    margin-top: 84px !important;
  }
  .mlpc-84 {
    margin-left: 84px !important;
  }
  .mrpc-84 {
    margin-right: 84px !important;
  }
  .mbpc-84 {
    margin-bottom: 84px !important;
  }
  .ptpc-84 {
    padding-top: 84px !important;
  }
  .plpc-84 {
    padding-left: 84px !important;
  }
  .prpc-84 {
    padding-right: 84px !important;
  }
  .pbpc-84 {
    padding-bottom: 84px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-84 {
    margin-top: 22.4vw !important;
  }
  .mlsp-84 {
    margin-left: 22.4vw !important;
  }
  .mrsp-84 {
    margin-right: 22.4vw !important;
  }
  .mbsp-84 {
    margin-bottom: 22.4vw !important;
  }
  .ptsp-84 {
    padding-top: 22.4vw !important;
  }
  .plsp-84 {
    padding-left: 22.4vw !important;
  }
  .prsp-84 {
    padding-right: 22.4vw !important;
  }
  .pbsp-84 {
    padding-bottom: 22.4vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-85 {
    margin-top: 85px !important;
  }
  .mlpc-85 {
    margin-left: 85px !important;
  }
  .mrpc-85 {
    margin-right: 85px !important;
  }
  .mbpc-85 {
    margin-bottom: 85px !important;
  }
  .ptpc-85 {
    padding-top: 85px !important;
  }
  .plpc-85 {
    padding-left: 85px !important;
  }
  .prpc-85 {
    padding-right: 85px !important;
  }
  .pbpc-85 {
    padding-bottom: 85px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-85 {
    margin-top: 22.66667vw !important;
  }
  .mlsp-85 {
    margin-left: 22.66667vw !important;
  }
  .mrsp-85 {
    margin-right: 22.66667vw !important;
  }
  .mbsp-85 {
    margin-bottom: 22.66667vw !important;
  }
  .ptsp-85 {
    padding-top: 22.66667vw !important;
  }
  .plsp-85 {
    padding-left: 22.66667vw !important;
  }
  .prsp-85 {
    padding-right: 22.66667vw !important;
  }
  .pbsp-85 {
    padding-bottom: 22.66667vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-86 {
    margin-top: 86px !important;
  }
  .mlpc-86 {
    margin-left: 86px !important;
  }
  .mrpc-86 {
    margin-right: 86px !important;
  }
  .mbpc-86 {
    margin-bottom: 86px !important;
  }
  .ptpc-86 {
    padding-top: 86px !important;
  }
  .plpc-86 {
    padding-left: 86px !important;
  }
  .prpc-86 {
    padding-right: 86px !important;
  }
  .pbpc-86 {
    padding-bottom: 86px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-86 {
    margin-top: 22.93333vw !important;
  }
  .mlsp-86 {
    margin-left: 22.93333vw !important;
  }
  .mrsp-86 {
    margin-right: 22.93333vw !important;
  }
  .mbsp-86 {
    margin-bottom: 22.93333vw !important;
  }
  .ptsp-86 {
    padding-top: 22.93333vw !important;
  }
  .plsp-86 {
    padding-left: 22.93333vw !important;
  }
  .prsp-86 {
    padding-right: 22.93333vw !important;
  }
  .pbsp-86 {
    padding-bottom: 22.93333vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-87 {
    margin-top: 87px !important;
  }
  .mlpc-87 {
    margin-left: 87px !important;
  }
  .mrpc-87 {
    margin-right: 87px !important;
  }
  .mbpc-87 {
    margin-bottom: 87px !important;
  }
  .ptpc-87 {
    padding-top: 87px !important;
  }
  .plpc-87 {
    padding-left: 87px !important;
  }
  .prpc-87 {
    padding-right: 87px !important;
  }
  .pbpc-87 {
    padding-bottom: 87px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-87 {
    margin-top: 23.2vw !important;
  }
  .mlsp-87 {
    margin-left: 23.2vw !important;
  }
  .mrsp-87 {
    margin-right: 23.2vw !important;
  }
  .mbsp-87 {
    margin-bottom: 23.2vw !important;
  }
  .ptsp-87 {
    padding-top: 23.2vw !important;
  }
  .plsp-87 {
    padding-left: 23.2vw !important;
  }
  .prsp-87 {
    padding-right: 23.2vw !important;
  }
  .pbsp-87 {
    padding-bottom: 23.2vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-88 {
    margin-top: 88px !important;
  }
  .mlpc-88 {
    margin-left: 88px !important;
  }
  .mrpc-88 {
    margin-right: 88px !important;
  }
  .mbpc-88 {
    margin-bottom: 88px !important;
  }
  .ptpc-88 {
    padding-top: 88px !important;
  }
  .plpc-88 {
    padding-left: 88px !important;
  }
  .prpc-88 {
    padding-right: 88px !important;
  }
  .pbpc-88 {
    padding-bottom: 88px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-88 {
    margin-top: 23.46667vw !important;
  }
  .mlsp-88 {
    margin-left: 23.46667vw !important;
  }
  .mrsp-88 {
    margin-right: 23.46667vw !important;
  }
  .mbsp-88 {
    margin-bottom: 23.46667vw !important;
  }
  .ptsp-88 {
    padding-top: 23.46667vw !important;
  }
  .plsp-88 {
    padding-left: 23.46667vw !important;
  }
  .prsp-88 {
    padding-right: 23.46667vw !important;
  }
  .pbsp-88 {
    padding-bottom: 23.46667vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-89 {
    margin-top: 89px !important;
  }
  .mlpc-89 {
    margin-left: 89px !important;
  }
  .mrpc-89 {
    margin-right: 89px !important;
  }
  .mbpc-89 {
    margin-bottom: 89px !important;
  }
  .ptpc-89 {
    padding-top: 89px !important;
  }
  .plpc-89 {
    padding-left: 89px !important;
  }
  .prpc-89 {
    padding-right: 89px !important;
  }
  .pbpc-89 {
    padding-bottom: 89px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-89 {
    margin-top: 23.73333vw !important;
  }
  .mlsp-89 {
    margin-left: 23.73333vw !important;
  }
  .mrsp-89 {
    margin-right: 23.73333vw !important;
  }
  .mbsp-89 {
    margin-bottom: 23.73333vw !important;
  }
  .ptsp-89 {
    padding-top: 23.73333vw !important;
  }
  .plsp-89 {
    padding-left: 23.73333vw !important;
  }
  .prsp-89 {
    padding-right: 23.73333vw !important;
  }
  .pbsp-89 {
    padding-bottom: 23.73333vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-90 {
    margin-top: 90px !important;
  }
  .mlpc-90 {
    margin-left: 90px !important;
  }
  .mrpc-90 {
    margin-right: 90px !important;
  }
  .mbpc-90 {
    margin-bottom: 90px !important;
  }
  .ptpc-90 {
    padding-top: 90px !important;
  }
  .plpc-90 {
    padding-left: 90px !important;
  }
  .prpc-90 {
    padding-right: 90px !important;
  }
  .pbpc-90 {
    padding-bottom: 90px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-90 {
    margin-top: 24vw !important;
  }
  .mlsp-90 {
    margin-left: 24vw !important;
  }
  .mrsp-90 {
    margin-right: 24vw !important;
  }
  .mbsp-90 {
    margin-bottom: 24vw !important;
  }
  .ptsp-90 {
    padding-top: 24vw !important;
  }
  .plsp-90 {
    padding-left: 24vw !important;
  }
  .prsp-90 {
    padding-right: 24vw !important;
  }
  .pbsp-90 {
    padding-bottom: 24vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-91 {
    margin-top: 91px !important;
  }
  .mlpc-91 {
    margin-left: 91px !important;
  }
  .mrpc-91 {
    margin-right: 91px !important;
  }
  .mbpc-91 {
    margin-bottom: 91px !important;
  }
  .ptpc-91 {
    padding-top: 91px !important;
  }
  .plpc-91 {
    padding-left: 91px !important;
  }
  .prpc-91 {
    padding-right: 91px !important;
  }
  .pbpc-91 {
    padding-bottom: 91px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-91 {
    margin-top: 24.26667vw !important;
  }
  .mlsp-91 {
    margin-left: 24.26667vw !important;
  }
  .mrsp-91 {
    margin-right: 24.26667vw !important;
  }
  .mbsp-91 {
    margin-bottom: 24.26667vw !important;
  }
  .ptsp-91 {
    padding-top: 24.26667vw !important;
  }
  .plsp-91 {
    padding-left: 24.26667vw !important;
  }
  .prsp-91 {
    padding-right: 24.26667vw !important;
  }
  .pbsp-91 {
    padding-bottom: 24.26667vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-92 {
    margin-top: 92px !important;
  }
  .mlpc-92 {
    margin-left: 92px !important;
  }
  .mrpc-92 {
    margin-right: 92px !important;
  }
  .mbpc-92 {
    margin-bottom: 92px !important;
  }
  .ptpc-92 {
    padding-top: 92px !important;
  }
  .plpc-92 {
    padding-left: 92px !important;
  }
  .prpc-92 {
    padding-right: 92px !important;
  }
  .pbpc-92 {
    padding-bottom: 92px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-92 {
    margin-top: 24.53333vw !important;
  }
  .mlsp-92 {
    margin-left: 24.53333vw !important;
  }
  .mrsp-92 {
    margin-right: 24.53333vw !important;
  }
  .mbsp-92 {
    margin-bottom: 24.53333vw !important;
  }
  .ptsp-92 {
    padding-top: 24.53333vw !important;
  }
  .plsp-92 {
    padding-left: 24.53333vw !important;
  }
  .prsp-92 {
    padding-right: 24.53333vw !important;
  }
  .pbsp-92 {
    padding-bottom: 24.53333vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-93 {
    margin-top: 93px !important;
  }
  .mlpc-93 {
    margin-left: 93px !important;
  }
  .mrpc-93 {
    margin-right: 93px !important;
  }
  .mbpc-93 {
    margin-bottom: 93px !important;
  }
  .ptpc-93 {
    padding-top: 93px !important;
  }
  .plpc-93 {
    padding-left: 93px !important;
  }
  .prpc-93 {
    padding-right: 93px !important;
  }
  .pbpc-93 {
    padding-bottom: 93px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-93 {
    margin-top: 24.8vw !important;
  }
  .mlsp-93 {
    margin-left: 24.8vw !important;
  }
  .mrsp-93 {
    margin-right: 24.8vw !important;
  }
  .mbsp-93 {
    margin-bottom: 24.8vw !important;
  }
  .ptsp-93 {
    padding-top: 24.8vw !important;
  }
  .plsp-93 {
    padding-left: 24.8vw !important;
  }
  .prsp-93 {
    padding-right: 24.8vw !important;
  }
  .pbsp-93 {
    padding-bottom: 24.8vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-94 {
    margin-top: 94px !important;
  }
  .mlpc-94 {
    margin-left: 94px !important;
  }
  .mrpc-94 {
    margin-right: 94px !important;
  }
  .mbpc-94 {
    margin-bottom: 94px !important;
  }
  .ptpc-94 {
    padding-top: 94px !important;
  }
  .plpc-94 {
    padding-left: 94px !important;
  }
  .prpc-94 {
    padding-right: 94px !important;
  }
  .pbpc-94 {
    padding-bottom: 94px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-94 {
    margin-top: 25.06667vw !important;
  }
  .mlsp-94 {
    margin-left: 25.06667vw !important;
  }
  .mrsp-94 {
    margin-right: 25.06667vw !important;
  }
  .mbsp-94 {
    margin-bottom: 25.06667vw !important;
  }
  .ptsp-94 {
    padding-top: 25.06667vw !important;
  }
  .plsp-94 {
    padding-left: 25.06667vw !important;
  }
  .prsp-94 {
    padding-right: 25.06667vw !important;
  }
  .pbsp-94 {
    padding-bottom: 25.06667vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-95 {
    margin-top: 95px !important;
  }
  .mlpc-95 {
    margin-left: 95px !important;
  }
  .mrpc-95 {
    margin-right: 95px !important;
  }
  .mbpc-95 {
    margin-bottom: 95px !important;
  }
  .ptpc-95 {
    padding-top: 95px !important;
  }
  .plpc-95 {
    padding-left: 95px !important;
  }
  .prpc-95 {
    padding-right: 95px !important;
  }
  .pbpc-95 {
    padding-bottom: 95px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-95 {
    margin-top: 25.33333vw !important;
  }
  .mlsp-95 {
    margin-left: 25.33333vw !important;
  }
  .mrsp-95 {
    margin-right: 25.33333vw !important;
  }
  .mbsp-95 {
    margin-bottom: 25.33333vw !important;
  }
  .ptsp-95 {
    padding-top: 25.33333vw !important;
  }
  .plsp-95 {
    padding-left: 25.33333vw !important;
  }
  .prsp-95 {
    padding-right: 25.33333vw !important;
  }
  .pbsp-95 {
    padding-bottom: 25.33333vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-96 {
    margin-top: 96px !important;
  }
  .mlpc-96 {
    margin-left: 96px !important;
  }
  .mrpc-96 {
    margin-right: 96px !important;
  }
  .mbpc-96 {
    margin-bottom: 96px !important;
  }
  .ptpc-96 {
    padding-top: 96px !important;
  }
  .plpc-96 {
    padding-left: 96px !important;
  }
  .prpc-96 {
    padding-right: 96px !important;
  }
  .pbpc-96 {
    padding-bottom: 96px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-96 {
    margin-top: 25.6vw !important;
  }
  .mlsp-96 {
    margin-left: 25.6vw !important;
  }
  .mrsp-96 {
    margin-right: 25.6vw !important;
  }
  .mbsp-96 {
    margin-bottom: 25.6vw !important;
  }
  .ptsp-96 {
    padding-top: 25.6vw !important;
  }
  .plsp-96 {
    padding-left: 25.6vw !important;
  }
  .prsp-96 {
    padding-right: 25.6vw !important;
  }
  .pbsp-96 {
    padding-bottom: 25.6vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-97 {
    margin-top: 97px !important;
  }
  .mlpc-97 {
    margin-left: 97px !important;
  }
  .mrpc-97 {
    margin-right: 97px !important;
  }
  .mbpc-97 {
    margin-bottom: 97px !important;
  }
  .ptpc-97 {
    padding-top: 97px !important;
  }
  .plpc-97 {
    padding-left: 97px !important;
  }
  .prpc-97 {
    padding-right: 97px !important;
  }
  .pbpc-97 {
    padding-bottom: 97px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-97 {
    margin-top: 25.86667vw !important;
  }
  .mlsp-97 {
    margin-left: 25.86667vw !important;
  }
  .mrsp-97 {
    margin-right: 25.86667vw !important;
  }
  .mbsp-97 {
    margin-bottom: 25.86667vw !important;
  }
  .ptsp-97 {
    padding-top: 25.86667vw !important;
  }
  .plsp-97 {
    padding-left: 25.86667vw !important;
  }
  .prsp-97 {
    padding-right: 25.86667vw !important;
  }
  .pbsp-97 {
    padding-bottom: 25.86667vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-98 {
    margin-top: 98px !important;
  }
  .mlpc-98 {
    margin-left: 98px !important;
  }
  .mrpc-98 {
    margin-right: 98px !important;
  }
  .mbpc-98 {
    margin-bottom: 98px !important;
  }
  .ptpc-98 {
    padding-top: 98px !important;
  }
  .plpc-98 {
    padding-left: 98px !important;
  }
  .prpc-98 {
    padding-right: 98px !important;
  }
  .pbpc-98 {
    padding-bottom: 98px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-98 {
    margin-top: 26.13333vw !important;
  }
  .mlsp-98 {
    margin-left: 26.13333vw !important;
  }
  .mrsp-98 {
    margin-right: 26.13333vw !important;
  }
  .mbsp-98 {
    margin-bottom: 26.13333vw !important;
  }
  .ptsp-98 {
    padding-top: 26.13333vw !important;
  }
  .plsp-98 {
    padding-left: 26.13333vw !important;
  }
  .prsp-98 {
    padding-right: 26.13333vw !important;
  }
  .pbsp-98 {
    padding-bottom: 26.13333vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-99 {
    margin-top: 99px !important;
  }
  .mlpc-99 {
    margin-left: 99px !important;
  }
  .mrpc-99 {
    margin-right: 99px !important;
  }
  .mbpc-99 {
    margin-bottom: 99px !important;
  }
  .ptpc-99 {
    padding-top: 99px !important;
  }
  .plpc-99 {
    padding-left: 99px !important;
  }
  .prpc-99 {
    padding-right: 99px !important;
  }
  .pbpc-99 {
    padding-bottom: 99px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-99 {
    margin-top: 26.4vw !important;
  }
  .mlsp-99 {
    margin-left: 26.4vw !important;
  }
  .mrsp-99 {
    margin-right: 26.4vw !important;
  }
  .mbsp-99 {
    margin-bottom: 26.4vw !important;
  }
  .ptsp-99 {
    padding-top: 26.4vw !important;
  }
  .plsp-99 {
    padding-left: 26.4vw !important;
  }
  .prsp-99 {
    padding-right: 26.4vw !important;
  }
  .pbsp-99 {
    padding-bottom: 26.4vw !important;
  }
}

@media screen and (min-width: 769px) {
  .mtpc-100 {
    margin-top: 100px !important;
  }
  .mlpc-100 {
    margin-left: 100px !important;
  }
  .mrpc-100 {
    margin-right: 100px !important;
  }
  .mbpc-100 {
    margin-bottom: 100px !important;
  }
  .ptpc-100 {
    padding-top: 100px !important;
  }
  .plpc-100 {
    padding-left: 100px !important;
  }
  .prpc-100 {
    padding-right: 100px !important;
  }
  .pbpc-100 {
    padding-bottom: 100px !important;
  }
}

@media screen and (max-width: 768px) {
  .mtsp-100 {
    margin-top: 26.66667vw !important;
  }
  .mlsp-100 {
    margin-left: 26.66667vw !important;
  }
  .mrsp-100 {
    margin-right: 26.66667vw !important;
  }
  .mbsp-100 {
    margin-bottom: 26.66667vw !important;
  }
  .ptsp-100 {
    padding-top: 26.66667vw !important;
  }
  .plsp-100 {
    padding-left: 26.66667vw !important;
  }
  .prsp-100 {
    padding-right: 26.66667vw !important;
  }
  .pbsp-100 {
    padding-bottom: 26.66667vw !important;
  }
}

/* ====================================================
Misc
==================================================== */
@media screen and (min-width: 769px) {
  @keyframes hand-floating {
    0% {
      top: 111px;
    }
    50% {
      top: 100px;
    }
    100% {
      top: 111px;
    }
  }
  @keyframes sdb04 {
    0% {
      transform: translate(0, 0);
    }
    20% {
      transform: translate(0, 0.52083vw);
    }
    40% {
      transform: translate(0, 0);
    }
  }
}

@media screen and (max-width: 768px) {
  @keyframes hand-floating {
    0% {
      top: 86px;
    }
    50% {
      top: 80px;
    }
    100% {
      top: 86px;
    }
  }
  @keyframes sdb04 {
    0% {
      transform: translate(0, 0);
    }
    20% {
      transform: translate(0, 2.66667vw);
    }
    40% {
      transform: translate(0, 0);
    }
  }
}

/* ====================================================
Base style & container
==================================================== */
body {
  color: black;
  font-size: 100%;
  line-height: 1.4;
  font-family: "Noto Sans CJK JP", "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "游ゴシック", "游ゴシック体", YuGothicMedium, YuGothic, "Yu Gothic", "メイリオ", Meiryo, "ＭＳ ゴシック", "MS Gothic", "HiraKakuProN-W3", "TakaoExゴシック", TakaoExGothic, "MotoyaLCedar", "Droid Sans Japanese", sans-serif, sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
  background-color: #f2f5f7;
}

.container {
  display: block;
  margin: 0 auto;
  padding: 0 20px;
}

@media screen and (max-width: 768px) {
  .container {
    padding: 0 5.33333vw;
  }
}

.container.container-xs {
  max-width: 950px;
}

.container.container-sm {
  max-width: 1010px;
}

.container.container-md {
  max-width: 1210px;
}

.container-fluid {
  width: 100%;
}

.pc {
  display: block;
}

.pc-in {
  display: inline-block;
}

.pc-flex {
  display: flex;
}

.pc-flex-in {
  display: inline-flex;
}

@media screen and (max-width: 768px) {
  .pc {
    display: none;
  }
  .pc-in, .pc-flex, .pc-flex-in {
    display: none;
  }
}

.sp {
  display: block;
}

.sp-in {
  display: inline-block;
}

.sp-flex {
  display: flex;
}

.sp-flex-in {
  display: inline-flex;
}

@media screen and (min-width: 769px) {
  .sp {
    display: none;
  }
  .sp-in, .sp-flex, .sp-flex-in {
    display: none;
  }
}

.page-content.scroll-fixed {
  overflow: hidden;
  height: 100vh;
}

main {
  margin-top: 90px;
}

@media screen and (max-width: 768px) {
  main {
    margin-top: 60px;
  }
}

/* ====================================================
Float & clear
==================================================== */
.clearfix {
  *zoom: 1;
}

.clearfix:before {
  display: table;
  content: '';
}

.clearfix:after {
  display: table;
  content: '';
  clear: both;
}

/* ====================================================
Header & footer
==================================================== */
#main-header {
  background-color: #FFF100;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.16);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}

@media screen and (max-width: 768px) {
  #main-header .container {
    padding: 0 20px;
  }
}

#main-header nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 90px;
  position: relative;
}

@media screen and (max-width: 1152px) {
  #main-header nav {
    height: 60px;
  }
}

#main-header nav .menu-logo {
  width: 312px;
}

@media screen and (max-width: 1152px) {
  #main-header nav .menu-logo {
    width: 195px;
  }
}

#main-header nav .main-menu {
  align-items: flex-start;
}

@media screen and (min-width: 1153px) {
  #main-header nav .main-menu {
    display: flex !important;
  }
}

@media screen and (max-width: 1152px) {
  #main-header nav .main-menu {
    display: none;
    position: absolute;
    width: calc(100% + 40px);
    top: 60px;
    right: -20px;
    background-color: #333333;
  }
}

#main-header nav .main-menu > li {
  display: inline-block;
}

@media screen and (max-width: 1152px) {
  #main-header nav .main-menu > li {
    display: block;
    border-bottom: 1px solid white;
  }
}

#main-header nav .main-menu > li:not(:last-child) a {
  padding: 25px;
}

#main-header nav .main-menu > li:not(:last-child) a span {
  display: block;
  text-align: center;
  width: 100%;
  color: #333333;
}

@media screen and (max-width: 1152px) {
  #main-header nav .main-menu > li:not(:last-child) a span {
    display: inline-block;
    width: auto;
    color: white;
  }
}

#main-header nav .main-menu > li:not(:last-child) a span.jp {
  font-weight: bold;
  font-size: 15px;
  font-size: 1.5rem;
}

#main-header nav .main-menu > li:not(:last-child) a span.en {
  text-transform: uppercase;
  font-size: 12px;
  font-size: 1.2rem;
}

#main-header nav .main-menu > li:last-child a {
  padding: 20px 28px;
}

@media screen and (min-width: 1153px) {
  #main-header nav .main-menu > li:last-child a {
    background-color: #FF9933;
  }
}

#main-header nav .main-menu > li:last-child a .fa {
  color: white;
  font-size: 24px;
  font-size: 2.4rem;
}

@media screen and (max-width: 1152px) {
  #main-header nav .main-menu > li:last-child a .fa {
    display: none;
  }
}

#main-header nav .main-menu > li:last-child a span {
  display: block;
  width: 100%;
  color: white;
}

@media screen and (max-width: 1152px) {
  #main-header nav .main-menu > li:last-child a span {
    display: inline-block;
    width: auto;
  }
}

#main-header nav .main-menu > li:last-child a span.jp {
  font-weight: bold;
  font-size: 15px;
  font-size: 1.5rem;
}

#main-header nav .main-menu > li:last-child a span.en {
  display: none;
  text-transform: uppercase;
  font-size: 12px;
  font-size: 1.2rem;
}

@media screen and (max-width: 1152px) {
  #main-header nav .main-menu > li:last-child a span.en {
    display: inline-block;
  }
}

#main-header nav .main-menu > li a {
  display: block;
  text-align: center;
  height: 90px;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  -webkit-backface-visibility: hidden;
}

@media screen and (max-width: 1152px) {
  #main-header nav .main-menu > li a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 60px 15px 20px !important;
    height: auto;
    position: relative;
  }
  #main-header nav .main-menu > li a:after {
    display: block;
    content: '\f054';
    font-family: FontAwesome, sans-serif;
    color: white;
    position: absolute;
    right: 20px;
    font-size: 20px;
    font-size: 2rem;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

#main-header nav .main-menu > li a:hover, #main-header nav .main-menu > li a.active {
  background-color: black !important;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  -webkit-backface-visibility: hidden;
}

#main-header nav .main-menu > li a:hover span, #main-header nav .main-menu > li a.active span {
  color: white !important;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  -webkit-backface-visibility: hidden;
}

#main-header nav .menu-sp-btn {
  display: none;
  width: 32px;
  height: 36px;
  position: absolute;
  top: 13px;
  right: 5px;
}

@media screen and (max-width: 1152px) {
  #main-header nav .menu-sp-btn {
    display: block;
  }
}

#main-header nav .menu-sp-btn img {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  -webkit-backface-visibility: hidden;
  width: 100%;
}

#main-header nav .menu-sp-btn img:last-child {
  opacity: 0;
  visibility: hidden;
}

#main-header nav .menu-sp-btn.is-clicked img:first-child {
  opacity: 0;
  visibility: hidden;
}

#main-header nav .menu-sp-btn.is-clicked img:last-child {
  opacity: 1;
  visibility: visible;
}

#main-footer {
  padding: 50px 0 20px;
  background-color: #FFF100;
}

@media screen and (max-width: 768px) {
  #main-footer {
    padding: 13.33333vw 0 7.2vw;
  }
}

@media screen and (max-width: 768px) {
  #main-footer .logo {
    padding: 0 5.33333vw;
  }
}

#main-footer .logo figure {
  text-align: center;
}

#main-footer .logo figure img {
  width: 390px;
}

@media screen and (max-width: 768px) {
  #main-footer .logo figure img {
    width: 100%;
  }
}

#main-footer .logo figure figcaption {
  color: #333333;
  margin-top: 30px;
  font-size: 15px;
  font-size: 1.5rem;
}

@media screen and (max-width: 768px) {
  #main-footer .logo figure figcaption {
    margin-top: 4.53333vw;
    font-size: 3.2vw;
    white-space: nowrap;
  }
}

#main-footer .sitemap {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 50px;
}

@media screen and (max-width: 768px) {
  #main-footer .sitemap {
    justify-content: flex-start;
    margin: 12vw -3.73333vw 0;
  }
}

#main-footer .sitemap li {
  border-right: 1px solid #333333;
}

@media screen and (max-width: 768px) {
  #main-footer .sitemap li {
    margin-bottom: 4.53333vw;
  }
}

#main-footer .sitemap li:last-child {
  border-right: none;
}

#main-footer .sitemap li a {
  display: block;
  color: #333333;
  font-size: 15px;
  font-size: 1.5rem;
  padding: 0 30px;
}

@media screen and (max-width: 768px) {
  #main-footer .sitemap li a {
    font-size: 3.2vw;
    padding: 0 3.73333vw;
  }
}

#main-footer .sitemap li a:hover {
  text-decoration: underline;
}

#main-footer .copyright {
  text-align: center;
  margin-top: 45px;
  font-size: 15px;
  font-size: 1.5rem;
}

@media screen and (max-width: 768px) {
  #main-footer .copyright {
    font-size: 3.2vw;
    margin-top: 4.8vw;
  }
}

.back-to-top {
  display: block;
  width: 100%;
  height: 90px;
  position: relative;
  background-color: #333333;
}

@media screen and (max-width: 768px) {
  .back-to-top {
    height: 12vw;
  }
}

.back-to-top:before {
  display: block;
  content: '';
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: 5px solid #FFF100;
  border-right: none;
  border-bottom: none;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -20%) rotate(45deg);
}

@media screen and (max-width: 768px) {
  .back-to-top:before {
    width: 4vw;
    height: 4vw;
  }
}

/* ====================================================
Components & modules
==================================================== */
.page-head-banner {
  padding: 134px 0;
}

@media screen and (max-width: 768px) {
  .page-head-banner {
    padding: 13.33333vw 0;
  }
}

.page-head-banner.page-privacy {
  background: url("../images/photos/head-bnr-privacy.jpg") no-repeat center center;
  background-size: cover;
}

@media screen and (max-width: 768px) {
  .page-head-banner.page-privacy {
    background: url("../images/photos/head-bnr-privacy-sp@2x.jpg") no-repeat center center;
    background-size: cover;
  }
}

.page-head-banner.page-contact, .page-head-banner.page-contact-error, .page-head-banner.page-contact-confirm, .page-head-banner.page-contact-result {
  background: url("../images/photos/head-bnr-contact.jpg") no-repeat center center;
  background-size: cover;
}

@media screen and (max-width: 768px) {
  .page-head-banner.page-contact, .page-head-banner.page-contact-error, .page-head-banner.page-contact-confirm, .page-head-banner.page-contact-result {
    background: url("../images/photos/head-bnr-contact-sp@2x.jpg") no-repeat center center;
    background-size: cover;
    padding: 6.66667vw 0 21.33333vw;
  }
}

.page-head-banner.page-about {
  background: url("../images/photos/head-bnr-about@2x.jpg") no-repeat center center;
  background-size: cover;
  text-align: right;
}

@media screen and (max-width: 768px) {
  .page-head-banner.page-about {
    background: url("../images/photos/head-bnr-about-sp@2x.jpg") no-repeat center center;
    background-size: cover;
  }
}

.page-head-banner.page-vision {
  background: url("../images/photos/head-bnr-vision@2x.jpg") no-repeat center center;
  background-size: cover;
}

@media screen and (max-width: 768px) {
  .page-head-banner.page-vision {
    background: url("../images/photos/head-bnr-vision-sp@2x.jpg") no-repeat center center;
    background-size: cover;
  }
}

.page-head-banner.page-business {
  background: url("../images/photos/head-bnr-business@2x.jpg") no-repeat center center;
  background-size: cover;
  text-align: right;
}

@media screen and (max-width: 768px) {
  .page-head-banner.page-business {
    background: url("../images/photos/head-bnr-business-sp@2x.jpg") no-repeat center center;
    background-size: cover;
  }
}

.page-head-banner.page-interview {
  background: url("../images/photos/head-bnr-interview@2x.jpg") no-repeat center center;
  background-size: cover;
}

@media screen and (max-width: 768px) {
  .page-head-banner.page-interview {
    background: url("../images/photos/head-bnr-interview-sp@2x.jpg") no-repeat center center;
    background-size: cover;
  }
}

.page-head-banner.page-recruit-01 {
  background: url("../images/photos/head-bnr-recruit@2x.jpg") no-repeat center center;
  background-size: cover;
  text-align: right;
}

@media screen and (max-width: 768px) {
  .page-head-banner.page-recruit-01 {
    background: url("../images/photos/head-bnr-recruit-sp@2x.jpg") no-repeat center center;
    background-size: cover;
  }
}

.page-head-banner.page-recruit-01 h2, .page-head-banner.page-recruit-01 p {
  color: black;
}

.page-head-banner.page-recruit-02 {
  background: url("../images/photos/head-bnr-recruit-02@2x.jpg") no-repeat center center;
  background-size: cover;
  text-align: right;
}

@media screen and (max-width: 768px) {
  .page-head-banner.page-recruit-02 {
    background: url("../images/photos/head-bnr-recruit-02-sp@2x.jpg") no-repeat center center;
    background-size: cover;
  }
}

.page-head-banner.page-recruit-02 h2, .page-head-banner.page-recruit-02 p {
  color: black;
}

.page-head-banner h2 {
  font-weight: 500;
  color: white;
  font-size: 24px;
  font-size: 2.4rem;
}

@media screen and (max-width: 768px) {
  .page-head-banner h2 {
    font-size: 4.8vw;
  }
}

.page-head-banner p {
  color: #FFF100;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 10px;
  font-size: 50px;
  font-size: 5rem;
}

@media screen and (max-width: 768px) {
  .page-head-banner p {
    font-size: 9.86667vw;
    margin-bottom: 1.33333vw;
  }
}

.page-head-banner h2, .page-head-banner p {
  white-space: nowrap;
}

.page-head-banner img {
  width: 181px;
}

@media screen and (max-width: 768px) {
  .page-head-banner img {
    width: 36.26667vw;
  }
}

.page-head-banner.txt-center img {
  width: 205px;
}

@media screen and (max-width: 768px) {
  .page-head-banner.txt-center img {
    width: 41.06667vw;
  }
}

.contact-block {
  overflow: hidden;
  position: relative;
  padding: 60px 0 100px;
  background: url("../images/photos/contact-block-bg.jpg") no-repeat center center;
  background-size: cover;
}

@media screen and (max-width: 768px) {
  .contact-block {
    padding: 13.33333vw 0;
    background: url("../images/photos/contact-block-bg-sp@2x.jpg") no-repeat center center;
    background-size: cover;
  }
}

.contact-block .section-ttl {
  text-align: center;
  margin-bottom: 120px;
}

@media screen and (max-width: 768px) {
  .contact-block .section-ttl {
    margin-bottom: 21.33333vw;
  }
}

.contact-block .section-ttl:after {
  display: block;
  content: '';
  background: url("../images/icons/section-contact-ttl-pattern@2x.png") no-repeat center center;
  width: 205px;
  height: 27px;
  background-size: 100% 100%;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 768px) {
  .contact-block .section-ttl:after {
    width: 41.06667vw;
    height: 5.33333vw;
  }
}

.contact-block .section-ttl h2 {
  font-weight: 500;
  color: white;
  font-size: 24px;
  font-size: 2.4rem;
}

@media screen and (max-width: 768px) {
  .contact-block .section-ttl h2 {
    font-size: 4.8vw;
  }
}

.contact-block .section-ttl p {
  color: #FFF100;
  font-size: 50px;
  font-size: 5rem;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .contact-block .section-ttl p {
    font-size: 9.86667vw;
  }
}

.contact-block .contact-info {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: -30px;
  margin-bottom: -30px;
}

.contact-block .contact-info .contact-phone {
  padding-left: 75px;
  position: relative;
  margin: 30px;
}

@media screen and (max-width: 768px) {
  .contact-block .contact-info .contact-phone {
    margin: 0 10.66667vw;
    padding-left: 13.33333vw;
  }
}

.contact-block .contact-info .contact-phone:before {
  display: block;
  content: '\f095';
  font-family: FontAwesome, sans-serif;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 65px;
  background-color: #FFF100;
  color: #333333;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  left: 0;
  font-size: 35px;
  font-size: 3.5rem;
}

@media screen and (max-width: 768px) {
  .contact-block .contact-info .contact-phone:before {
    width: 10.66667vw;
    height: 10.66667vw;
    line-height: 10.66667vw;
    font-size: 6.4vw;
  }
}

.contact-block .contact-info .contact-phone a, .contact-block .contact-info .contact-phone p {
  color: white;
  white-space: nowrap;
}

.contact-block .contact-info .contact-phone a {
  line-height: 1.2;
  font-weight: bold;
  font-size: 60px;
  font-size: 6rem;
}

@media screen and (max-width: 768px) {
  .contact-block .contact-info .contact-phone a {
    font-size: 10.4vw;
    letter-spacing: -0.53333vw;
  }
}

.contact-block .contact-info .contact-phone p {
  font-weight: 500;
  font-size: 20px;
  font-size: 2rem;
}

@media screen and (max-width: 768px) {
  .contact-block .contact-info .contact-phone p {
    line-height: 2.2;
    font-size: 3.2vw;
  }
}

.contact-block .contact-info .contact-email {
  margin: 30px;
}

@media screen and (max-width: 768px) {
  .contact-block .contact-info .contact-email {
    margin: 5.33333vw;
  }
}

.contact-block:hover .bg-wrapper {
  transform: scale(1.1);
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  -webkit-backface-visibility: hidden;
}

.contact-block:hover .bg-wrapper:before {
  opacity: 1;
  visibility: visible;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  -webkit-backface-visibility: hidden;
}

.section-heading {
  text-align: center;
}

.section-heading h2 {
  display: inline-block;
  align-items: center;
  position: relative;
  padding-left: 100px;
}

@media screen and (max-width: 768px) {
  .section-heading h2 {
    padding-left: 16vw;
  }
}

.section-heading h2:after {
  display: block;
  content: '';
  width: 120px;
  height: 1px;
  background-color: #333333;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media screen and (max-width: 768px) {
  .section-heading h2:after {
    width: 19.2vw;
  }
}

.section-heading h2 span {
  display: inline-block;
  background-color: #FFF100;
  padding: 9px 30px;
  font-weight: 500;
  color: #333333;
  font-size: 30px;
  font-size: 3rem;
}

@media screen and (max-width: 768px) {
  .section-heading h2 span {
    font-size: 4.8vw;
    padding: 1.33333vw 4.8vw;
  }
}

figure.img-center {
  text-align: center;
}

/* ====================================================
Typography
==================================================== */
.txt-center {
  text-align: center !important;
}

.txt-left {
  text-align: left !important;
}

.txt-right {
  text-align: right !important;
}

.txt-justify {
  text-align: justify !important;
}

.txt-basic, .company-profiles .company-profiles-block dl dt, .company-profiles .company-profiles-block dl dd, .relationship-awards table tbody td, .relationship-awards .awards-list li span, .construction-result .construction-result-list .col ul li, .vision-top-message article p, .personel-image figure figcaption p, .employee-interview .interview-intro-block .block-content .img figcaption .qualifications dt, .employee-interview .interview-intro-block .block-content .img figcaption .qualifications dd, .employee-interview .interview-intro-block .block-content .desc p {
  line-height: 2;
  color: #333333;
  font-size: 15px;
  font-size: 1.5rem;
}

@media screen and (max-width: 768px) {
  .txt-basic, .company-profiles .company-profiles-block dl dt, .company-profiles .company-profiles-block dl dd, .relationship-awards table tbody td, .relationship-awards .awards-list li span, .construction-result .construction-result-list .col ul li, .vision-top-message article p, .personel-image figure figcaption p, .employee-interview .interview-intro-block .block-content .img figcaption .qualifications dt, .employee-interview .interview-intro-block .block-content .img figcaption .qualifications dd, .employee-interview .interview-intro-block .block-content .desc p {
    font-size: 3.73333vw;
  }
}

/* ====================================================
Buttons, input, .etc
==================================================== */
.btn {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
  position: relative;
  overflow: hidden;
}

.btn:before {
  border-radius: 80px;
}

.btn.btn-solid-yellow {
  background-color: black;
  padding: 26px 30px;
  border-radius: 80px;
}

@media screen and (max-width: 768px) {
  .btn.btn-solid-yellow {
    padding: 4vw 4.26667vw;
  }
}

.btn.btn-solid-yellow:before {
  display: block;
  content: '';
  background-color: #FFF100;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  -webkit-backface-visibility: hidden;
}

.btn.btn-solid-yellow span {
  position: relative;
  color: #333333;
  font-weight: bold;
  font-size: 20px;
  font-size: 2rem;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  -webkit-backface-visibility: hidden;
}

@media screen and (max-width: 768px) {
  .btn.btn-solid-yellow span {
    white-space: nowrap;
    font-size: 3.46667vw;
  }
}

.btn.btn-solid-yellow.scroll-left {
  background-color: #FFF100;
}

.btn.btn-solid-yellow.scroll-left:before {
  left: 100%;
  background-color: black;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  -webkit-backface-visibility: hidden;
}

.btn.btn-solid-yellow.scroll-left:hover:before {
  left: 0;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  -webkit-backface-visibility: hidden;
}

.btn.btn-solid-orange {
  background-color: black;
  padding: 26px 30px;
  border-radius: 80px;
}

@media screen and (max-width: 768px) {
  .btn.btn-solid-orange {
    padding: 4vw 4.26667vw;
  }
}

.btn.btn-solid-orange:before {
  display: block;
  content: '';
  background-color: #FF9933;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  -webkit-backface-visibility: hidden;
}

.btn.btn-solid-orange span {
  position: relative;
  color: #333333;
  font-weight: bold;
  font-size: 20px;
  font-size: 2rem;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  -webkit-backface-visibility: hidden;
}

@media screen and (max-width: 768px) {
  .btn.btn-solid-orange span {
    white-space: nowrap;
    font-size: 3.46667vw;
  }
}

.btn.btn-solid-white {
  background-color: #FFF100;
  padding: 12px 20px 12px 100px;
  border-radius: 45px;
}

@media screen and (max-width: 768px) {
  .btn.btn-solid-white {
    padding: 3.46667vw 5.33333vw 3.2vw 26.66667vw;
  }
}

.btn.btn-solid-white:before {
  display: block;
  content: '';
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  -webkit-backface-visibility: hidden;
}

.btn.btn-solid-white span {
  position: relative;
  color: #333333;
  font-weight: bold;
  font-size: 15px;
  font-size: 1.5rem;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  -webkit-backface-visibility: hidden;
}

@media screen and (max-width: 768px) {
  .btn.btn-solid-white span {
    font-size: 3.73333vw;
  }
}

.btn.btn-solid-white .arrow-right {
  width: 18px;
  height: 14px;
  margin-left: 65px;
}

@media screen and (max-width: 768px) {
  .btn.btn-solid-white .arrow-right {
    width: 4.8vw;
    height: 3.73333vw;
    margin-left: 17.33333vw;
  }
}

.btn.btn-solid-white .arrow-right:after {
  width: 9px;
  height: 9px;
}

@media screen and (max-width: 768px) {
  .btn.btn-solid-white .arrow-right:after {
    width: 2.4vw;
    height: 2.4vw;
  }
}

.btn .fa {
  position: relative;
  color: #333333;
  font-size: 35px;
  font-size: 3.5rem;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  -webkit-backface-visibility: hidden;
}

@media screen and (max-width: 768px) {
  .btn .fa {
    font-size: 6.66667vw;
  }
}

.btn .fa.icon-left {
  margin-right: 40px;
}

.btn .fa.fa-hidden {
  color: transparent !important;
}

.btn .arrow-right {
  display: block;
  content: '';
  width: 27px;
  height: 20px;
  position: relative;
  margin-left: 50px;
}

@media screen and (max-width: 768px) {
  .btn .arrow-right {
    width: 5.33333vw;
    height: 3.73333vw;
  }
}

.btn .arrow-right:before {
  display: block;
  content: '';
  width: 100%;
  height: 2px;
  background-color: #333333;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  -webkit-backface-visibility: hidden;
}

.btn .arrow-right:after {
  display: block;
  content: '';
  width: 15px;
  height: 15px;
  background-color: transparent;
  border: 2px solid #333333;
  border-left: none;
  border-bottom: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  right: 0;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  -webkit-backface-visibility: hidden;
}

@media screen and (max-width: 768px) {
  .btn .arrow-right:after {
    width: 3.2vw;
    height: 3.2vw;
  }
}

.btn .arrow-left {
  display: block;
  content: '';
  width: 27px;
  height: 20px;
  position: relative;
  margin-right: 50px;
}

@media screen and (max-width: 768px) {
  .btn .arrow-left {
    width: 5.33333vw;
    height: 3.73333vw;
  }
}

.btn .arrow-left:before {
  display: block;
  content: '';
  width: 100%;
  height: 2px;
  background-color: #333333;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  -webkit-backface-visibility: hidden;
}

@media screen and (max-width: 768px) {
  .btn .arrow-left:before {
    height: 0.53333vw;
  }
}

.btn .arrow-left:after {
  display: block;
  content: '';
  width: 15px;
  height: 15px;
  background-color: transparent;
  border: 2px solid #333333;
  border-left: none;
  border-bottom: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(-135deg);
  left: 0;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  -webkit-backface-visibility: hidden;
}

@media screen and (max-width: 768px) {
  .btn .arrow-left:after {
    width: 3.2vw;
    height: 3.2vw;
  }
}

.btn:hover:before {
  left: 100%;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  -webkit-backface-visibility: hidden;
}

.btn:hover.btn-solid-yellow .fa {
  color: white;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  -webkit-backface-visibility: hidden;
}

.btn:hover.btn-solid-yellow span {
  color: white;
}

.btn:hover.btn-solid-yellow .arrow-right:before, .btn:hover.btn-solid-yellow .arrow-left:before {
  background-color: #FFFFFF;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  -webkit-backface-visibility: hidden;
}

.btn:hover.btn-solid-yellow .arrow-right:after, .btn:hover.btn-solid-yellow .arrow-left:after {
  border-color: white;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  -webkit-backface-visibility: hidden;
}

noscript {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 20px;
  font-size: 2rem;
  font-weight: bold;
  background-color: rgba(255, 255, 255, 0.9);
}

@media screen and (min-width: 769px) {
  a[href^="tel"] {
    pointer-events: none;
  }
}

.first-view {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9000;
  background-color: black;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  -webkit-backface-visibility: hidden;
}

.first-view .toggle-btn {
  width: 100%;
  position: absolute;
  text-align: center;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.first-view .toggle-btn .toggle {
  display: inline-block;
  width: 120px;
  height: 120px;
}

@media screen and (max-width: 768px) {
  .first-view .toggle-btn .toggle {
    width: 96px;
    height: 96px;
  }
}

.first-view .toggle-btn .toggle:hover {
  cursor: pointer;
}

.first-view .toggle-btn .pointing-hand {
  display: inline-block;
  width: 62px;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  top: 111px;
  animation: hand-floating 1s infinite;
}

@media screen and (max-width: 768px) {
  .first-view .toggle-btn .pointing-hand {
    width: 50px;
    top: 86px;
  }
}

.first-view .toggle-btn .message {
  color: white;
  font-weight: bold;
  font-size: 30px;
  font-size: 3rem;
  margin-top: 96px;
}

@media screen and (max-width: 768px) {
  .first-view .toggle-btn .message {
    margin-top: 77px;
    font-size: 24px;
    font-size: 2.4rem;
  }
}

.first-view .toggle-btn .page-logo {
  display: inline-block;
  width: 291px;
  margin-top: 140px;
}

.first-view .toggle-btn .page-logo img:last-child {
  display: none;
}

@media screen and (max-width: 768px) {
  .first-view .toggle-btn .page-logo {
    margin-top: 110px;
    width: 232px;
  }
}

.first-view.is-on {
  background-color: #FFF100;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  -webkit-backface-visibility: hidden;
}

.first-view.is-on .pointing-hand, .first-view.is-on .message {
  opacity: 0;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  -webkit-backface-visibility: hidden;
}

.first-view.is-on .page-logo img:first-child {
  display: none;
}

.first-view.is-on .page-logo img:last-child {
  display: block;
}

.main-visual {
  position: relative;
}

.main-visual .main-slideshow {
  position: relative;
  overflow: hidden;
}

.main-visual .main-slideshow .item {
  position: relative;
  text-align: center;
  width: 100%;
}

.main-visual .main-slideshow .item:not(:first-child) {
  position: absolute;
  top: 0;
  left: 0;
}

.main-visual .main-slideshow .item.show_ img {
  opacity: 1;
}

.main-visual .main-slideshow .item.zoom_ img {
  transform: scale(1.1);
}

.main-visual .main-slideshow .item img {
  display: block;
  width: 100%;
  opacity: 0;
  transform: scale(1);
  transition: opacity 2s linear, transform 7.5s linear;
}

@media screen and (max-width: 768px) {
  .main-visual .main-slideshow .item img.pc {
    display: none;
  }
}

@media screen and (min-width: 769px) {
  .main-visual .main-slideshow .item img.sp {
    display: none;
  }
}

.main-visual .main-visual-message {
  display: block;
  width: 100%;
  position: absolute;
  top: 11.45833vw;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}

@media screen and (max-width: 768px) {
  .main-visual .main-visual-message {
    top: 41.33333vw;
  }
}

.main-visual .main-visual-message .top-message {
  text-align: center;
  color: white;
  white-space: nowrap;
  font-family: southpawregular, sans-serif;
  font-size: 5.20833vw;
}

@media screen and (max-width: 768px) {
  .main-visual .main-visual-message .top-message {
    font-size: 8.53333vw;
  }
}

@media screen and (max-width: 768px) {
  .main-visual .main-visual-message #heartline {
    transform: scale(2.5);
    margin-top: 10.66667vw;
    margin-bottom: 13.33333vw;
  }
}

.main-visual .main-visual-message .small-message {
  color: white;
  font-weight: bold;
  text-align: center;
  font-size: 1.25vw;
}

@media screen and (max-width: 768px) {
  .main-visual .main-visual-message .small-message {
    font-size: 4.8vw;
  }
}

.main-visual .main-visual-message .scroll-down-btn {
  display: block;
  text-align: center;
  margin-top: 1.5625vw;
}

.main-visual .main-visual-message .scroll-down-btn span {
  display: block;
  width: 100%;
  color: white;
  font-weight: bold;
  font-size: 0.625vw;
}

@media screen and (max-width: 768px) {
  .main-visual .main-visual-message .scroll-down-btn span {
    font-size: 3.2vw;
  }
}

.main-visual .main-visual-message .scroll-down-btn img {
  width: 0.67708vw;
  animation: sdb04 2s infinite;
}

@media screen and (max-width: 768px) {
  .main-visual .main-visual-message .scroll-down-btn img {
    width: 3.46667vw;
  }
}

.index-recruit-block {
  background: url("../images/photos/index-recruit-bg@2x.jpg") no-repeat center center;
  background-size: cover;
  padding: 100px 0 110px;
}

@media screen and (max-width: 768px) {
  .index-recruit-block {
    background: url("../images/photos/index-recruit-bg-sp@2x.jpg") no-repeat center center;
    background-size: cover;
  }
}

@media screen and (max-width: 768px) {
  .index-recruit-block {
    padding: 13.33333vw 0 26.66667vw;
  }
}

.index-recruit-block .section-ttl {
  position: relative;
  text-align: right;
  margin-bottom: 50px;
}

@media screen and (max-width: 768px) {
  .index-recruit-block .section-ttl {
    margin-bottom: 13.33333vw;
  }
}

.index-recruit-block .section-ttl h2, .index-recruit-block .section-ttl p {
  color: #333333;
}

.index-recruit-block .section-ttl h2 {
  font-weight: 500;
  font-size: 24px;
  font-size: 2.4rem;
}

@media screen and (max-width: 768px) {
  .index-recruit-block .section-ttl h2 {
    font-size: 4.8vw;
  }
}

.index-recruit-block .section-ttl p {
  font-weight: bold;
  font-size: 50px;
  font-size: 5rem;
}

@media screen and (max-width: 768px) {
  .index-recruit-block .section-ttl p {
    font-size: 9.86667vw;
  }
}

.index-recruit-block .section-ttl:after {
  display: inline-block;
  content: '';
  background: url("../images/icons/recruit-ttl-pattern@2x.png") no-repeat center center;
  width: 179px;
  height: 27px;
  background-size: 100% 100%;
}

@media screen and (max-width: 768px) {
  .index-recruit-block .section-ttl:after {
    width: 36vw;
    height: 5.33333vw;
  }
}

.index-recruit-block .recruit-posts {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.index-recruit-block .recruit-posts .item {
  width: 47%;
}

@media screen and (max-width: 768px) {
  .index-recruit-block .recruit-posts .item {
    width: 100%;
    margin-bottom: 13.33333vw;
  }
  .index-recruit-block .recruit-posts .item:last-child {
    margin-bottom: 0;
  }
}

.index-recruit-block .recruit-posts .item .img {
  overflow: hidden;
  position: relative;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  -webkit-backface-visibility: hidden;
}

.index-recruit-block .recruit-posts .item .img:before {
  display: block;
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  pointer-events: none;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  -webkit-backface-visibility: hidden;
}

.index-recruit-block .recruit-posts .item .img img {
  width: 100%;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  -webkit-backface-visibility: hidden;
}

.index-recruit-block .recruit-posts .item .img:hover {
  cursor: pointer;
}

.index-recruit-block .recruit-posts .item .img:hover:before {
  opacity: 1;
  visibility: visible;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  -webkit-backface-visibility: hidden;
}

.index-recruit-block .recruit-posts .item .img:hover img {
  transform: scale(1.1);
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  -webkit-backface-visibility: hidden;
}

.index-recruit-block .recruit-posts .item .content .title {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-weight: bold;
  margin: 30px 0;
  color: #333333;
  font-size: 24px;
  font-size: 2.4rem;
}

@media screen and (max-width: 768px) {
  .index-recruit-block .recruit-posts .item .content .title {
    margin: 5.33333vw 0;
    font-size: 4.8vw;
  }
}

.index-recruit-block .recruit-posts .item .content .title .tagline {
  color: white;
  background-color: #333333;
  padding: 9px 26px;
  margin-right: 20px;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 768px) {
  .index-recruit-block .recruit-posts .item .content .title .tagline {
    padding: 1.33333vw 3.2vw;
    margin-right: 2.66667vw;
    font-size: 3.2vw;
  }
}

.index-recruit-block .recruit-posts .item .content .excerpt {
  color: #333333;
  line-height: 2;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 768px) {
  .index-recruit-block .recruit-posts .item .content .excerpt {
    font-size: 3.73333vw;
  }
}

#index-intro-blocks .block {
  width: 100%;
  min-height: 500px;
  padding: 50px 0 45px;
  position: relative;
}

@media screen and (max-width: 768px) {
  #index-intro-blocks .block {
    min-height: auto;
    padding: 10.66667vw 0 9.33333vw;
  }
}

#index-intro-blocks .block.block-01 {
  background: url("../images/photos/intro-index-block-01.jpg") no-repeat center center;
  background-size: cover;
}

@media screen and (max-width: 768px) {
  #index-intro-blocks .block.block-01 {
    background: url("../images/photos/intro-index-block-01-sp.jpg") no-repeat center center;
    background-size: cover;
  }
}

#index-intro-blocks .block.block-02 {
  background: url("../images/photos/intro-index-block-02.jpg") no-repeat center center;
  background-size: cover;
}

@media screen and (max-width: 768px) {
  #index-intro-blocks .block.block-02 {
    background: url("../images/photos/intro-index-block-02-sp.jpg") no-repeat center center;
    background-size: cover;
  }
}

#index-intro-blocks .block.block-03 {
  background: url("../images/photos/intro-index-block-03.jpg") no-repeat center center;
  background-size: cover;
}

@media screen and (max-width: 768px) {
  #index-intro-blocks .block.block-03 {
    background: url("../images/photos/intro-index-block-03-sp.jpg") no-repeat center center;
    background-size: cover;
  }
}

#index-intro-blocks .block.block-04 {
  background: url("../images/photos/intro-index-block-04.jpg") no-repeat center center;
  background-size: cover;
}

@media screen and (max-width: 768px) {
  #index-intro-blocks .block.block-04 {
    background: url("../images/photos/intro-index-block-04-sp.jpg") no-repeat center center;
    background-size: cover;
  }
}

#index-intro-blocks .block:before, #index-intro-blocks .block:after {
  display: block;
  content: '';
  position: absolute;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  -webkit-backface-visibility: hidden;
}

#index-intro-blocks .block:before {
  background-color: #f2f5f7;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

#index-intro-blocks .block:after {
  background-color: #fff;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

#index-intro-blocks .block:nth-child(odd) {
  text-align: right;
}

#index-intro-blocks .block:nth-child(odd) .section-ttl:after {
  background: url("../images/icons/ttl-pattern-right@2x.png") no-repeat center center;
  background-size: 100% 100%;
}

#index-intro-blocks .block:nth-child(odd) .intro-txt {
  background: -moz-linear-gradient(left, rgba(125, 185, 232, 0) 40%, rgba(0, 0, 0, 0.4) 100%);
  background: -webkit-linear-gradient(left, rgba(125, 185, 232, 0) 40%, rgba(0, 0, 0, 0.4) 100%);
  background: linear-gradient(to right, rgba(125, 185, 232, 0) 40%, rgba(0, 0, 0, 0.4) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#007db9e8', endColorstr='#66000000', GradientType=1);
}

@media screen and (max-width: 768px) {
  #index-intro-blocks .block:nth-child(odd) .intro-txt {
    background: -moz-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 29%);
    background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 29%);
    background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 29%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#66000000', GradientType=1);
  }
}

#index-intro-blocks .block:nth-child(odd).scroll-reveal:before {
  left: 100%;
  transition: linear .5s;
}

#index-intro-blocks .block:nth-child(odd).scroll-reveal:after {
  left: 100%;
  transition: linear .5s;
  transition-delay: .5s;
}

#index-intro-blocks .block:nth-child(even) .section-ttl:after {
  background: url("../images/icons/ttl-pattern-left@2x.png") no-repeat center center;
  background-size: 100% 100%;
}

#index-intro-blocks .block:nth-child(even) .intro-txt {
  background: -moz-linear-gradient(left, rgba(0, 0, 0, 0.4) 0%, rgba(125, 185, 232, 0) 60%);
  background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.4) 0%, rgba(125, 185, 232, 0) 60%);
  background: linear-gradient(to right, rgba(0, 0, 0, 0.4) 0%, rgba(125, 185, 232, 0) 60%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#66000000', endColorstr='#007db9e8', GradientType=1);
}

@media screen and (max-width: 768px) {
  #index-intro-blocks .block:nth-child(even) .intro-txt {
    background: -moz-linear-gradient(left, rgba(0, 0, 0, 0.4) 71%, rgba(0, 0, 0, 0) 100%);
    background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.4) 71%, rgba(0, 0, 0, 0) 100%);
    background: linear-gradient(to right, rgba(0, 0, 0, 0.4) 71%, rgba(0, 0, 0, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#66000000', endColorstr='#00000000', GradientType=1);
  }
}

#index-intro-blocks .block:nth-child(even).scroll-reveal:before {
  left: -100%;
  transition: linear .5s;
}

#index-intro-blocks .block:nth-child(even).scroll-reveal:after {
  left: -100%;
  transition: linear .5s;
  transition-delay: .5s;
}

#index-intro-blocks .block .section-ttl {
  margin-bottom: 30px;
}

#index-intro-blocks .block .section-ttl:after {
  display: inline-block;
  content: '';
  width: 179px;
  height: 27px;
}

@media screen and (max-width: 768px) {
  #index-intro-blocks .block .section-ttl:after {
    width: 36vw;
    height: 5.33333vw;
  }
}

#index-intro-blocks .block .section-ttl h2 {
  color: white;
  font-weight: 500;
  font-size: 24px;
  font-size: 2.4rem;
}

@media screen and (max-width: 768px) {
  #index-intro-blocks .block .section-ttl h2 {
    font-size: 4.8vw;
  }
}

#index-intro-blocks .block .section-ttl p {
  color: #FFF100;
  font-weight: bold;
  font-size: 50px;
  font-size: 5rem;
}

@media screen and (max-width: 768px) {
  #index-intro-blocks .block .section-ttl p {
    font-size: 9.86667vw;
  }
}

#index-intro-blocks .block .intro-txt {
  padding: 30px 0;
  color: white;
  line-height: 2;
  font-size: 15px;
  font-size: 1.5rem;
}

@media screen and (max-width: 768px) {
  #index-intro-blocks .block .intro-txt {
    padding: 5.33333vw 0;
    font-size: 3.2vw;
  }
}

#index-intro-blocks .block .btn {
  margin-top: 60px;
}

@media screen and (max-width: 768px) {
  #index-intro-blocks .block .btn {
    margin-top: 6.66667vw;
  }
}

@media screen and (max-width: 768px) {
  #index-intro-blocks .block > .container:last-child {
    text-align: center;
  }
}

.privacy-policy-article {
  padding: 100px 0;
  background: url("../images/photos/gray-stripped-pattern.png") #fff no-repeat center top 200px;
  background-size: 100% auto;
}

@media screen and (max-width: 768px) {
  .privacy-policy-article {
    background: url("../images/photos/gray-stripped-pattern-sp@2x.png") #fff no-repeat center top 92vw;
    background-size: 100% auto;
    padding: 8vw 0 26.66667vw;
  }
}

.privacy-policy-article h3 {
  margin-top: 100px;
  margin-bottom: 30px;
  color: #333333;
  font-size: 40px;
  font-size: 4rem;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .privacy-policy-article h3 {
    font-size: 5.33333vw;
    margin-top: 16vw;
    margin-bottom: 5.33333vw;
  }
}

.privacy-policy-article h3:first-child {
  margin-top: 0;
}

.privacy-policy-article ul, .privacy-policy-article p {
  color: #333333;
  line-height: 1.8;
  font-size: 15px;
  font-size: 1.5rem;
}

@media screen and (max-width: 768px) {
  .privacy-policy-article ul, .privacy-policy-article p {
    font-size: 3.2vw;
  }
}

.section-contact-form {
  padding-top: 100px;
  background-color: #fff;
}

@media screen and (max-width: 768px) {
  .section-contact-form {
    padding-top: 8vw;
  }
}

.section-contact-form .contact-form-heading {
  padding-bottom: 50px;
  background-color: #fff;
}

@media screen and (max-width: 768px) {
  .section-contact-form .contact-form-heading {
    padding-bottom: 13.33333vw;
  }
}

.section-contact-form .contact-form-heading .section-ttl {
  text-align: center;
  margin-bottom: 100px;
}

@media screen and (max-width: 768px) {
  .section-contact-form .contact-form-heading .section-ttl {
    margin-bottom: 13.33333vw;
  }
}

.section-contact-form .contact-form-heading .section-ttl .fa {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  color: #FFF100;
  background-color: #333333;
  font-size: 40px;
  font-size: 4rem;
  line-height: 60px;
}

@media screen and (max-width: 768px) {
  .section-contact-form .contact-form-heading .section-ttl .fa {
    width: 13.33333vw;
    height: 13.33333vw;
    font-size: 10vw;
    line-height: 13.33333vw;
  }
}

.section-contact-form .contact-form-heading .section-ttl a {
  display: block;
  color: #333333;
  font-size: 60px;
  font-size: 6rem;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .section-contact-form .contact-form-heading .section-ttl a {
    font-size: 10.66667vw;
  }
}

.section-contact-form .contact-form-heading .section-ttl p {
  color: #333333;
  font-size: 20px;
  font-size: 2rem;
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  .section-contact-form .contact-form-heading .section-ttl p {
    font-size: 4.8vw;
  }
}

.section-contact-form .contact-form-heading .message {
  text-align: center;
  line-height: 1.8;
  font-size: 20px;
  font-size: 2rem;
}

@media screen and (max-width: 768px) {
  .section-contact-form .contact-form-heading .message {
    text-align: left;
    font-size: 3.73333vw;
    line-height: 2;
  }
}

.section-contact-form .contact-form-heading .notice {
  color: #333333;
  font-weight: bold;
  text-align: center;
  font-size: 40px;
  font-size: 4rem;
}

@media screen and (max-width: 768px) {
  .section-contact-form .contact-form-heading .notice {
    font-size: 5.33333vw;
    margin-bottom: -8vw;
  }
}

.section-contact-form .contact-form-heading .notice.error {
  color: #E20045;
}

@media screen and (max-width: 768px) {
  .section-contact-form .contact-form-heading.page-contact-result {
    padding-bottom: 0;
  }
}

.section-contact-form .contact-form-heading.page-contact-result .notice {
  margin-bottom: 50px;
}

@media screen and (max-width: 768px) {
  .section-contact-form .contact-form-heading.page-contact-result .notice {
    margin-bottom: 8vw;
  }
}

.section-contact-form .contact-form-body {
  background-color: #FAFAFA;
  padding-top: 50px;
}

.section-contact-form .contact-form-body .error-message {
  margin-bottom: 50px;
  padding: 0 30px;
}

@media screen and (max-width: 768px) {
  .section-contact-form .contact-form-body .error-message {
    margin-bottom: 0;
    padding: 0;
  }
}

.section-contact-form .contact-form-body .error-message p {
  color: #E20045;
  font-size: 20px;
  font-size: 2rem;
  font-weight: 500;
  line-height: 2;
}

@media screen and (max-width: 768px) {
  .section-contact-form .contact-form-body .error-message p {
    font-size: 4.8vw;
  }
}

.section-contact-form .contact-form-body .form-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 25px 30px;
  border-bottom: 1px solid #DEDEDE;
}

@media screen and (max-width: 768px) {
  .section-contact-form .contact-form-body .form-row {
    border-bottom: none;
    padding: 6.66667vw 0;
  }
}

.section-contact-form .contact-form-body .form-row:last-child {
  padding-bottom: 75px;
  border-bottom: none;
}

@media screen and (max-width: 768px) {
  .section-contact-form .contact-form-body .form-row:last-child {
    padding-bottom: 8vw;
  }
}

.section-contact-form .contact-form-body .form-row .form-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 2px;
  width: 29.67%;
}

@media screen and (max-width: 768px) {
  .section-contact-form .contact-form-body .form-row .form-label {
    width: 100%;
    margin-top: 0;
    margin-bottom: 5.33333vw;
  }
}

.section-contact-form .contact-form-body .form-row .form-label label {
  font-weight: 500;
  margin: 8px 0;
  font-size: 20px;
  font-size: 2rem;
}

@media screen and (max-width: 768px) {
  .section-contact-form .contact-form-body .form-row .form-label label {
    margin: 0;
    font-size: 4.8vw;
  }
}

.section-contact-form .contact-form-body .form-row .form-label .tag {
  font-weight: bold;
  width: 60px;
  height: 34px;
  text-align: center;
  line-height: 34px;
  font-size: 15px;
  font-size: 1.5rem;
}

@media screen and (max-width: 768px) {
  .section-contact-form .contact-form-body .form-row .form-label .tag {
    font-size: 4vw;
    width: 16vw;
    height: 9.06667vw;
    line-height: 9.06667vw;
  }
}

.section-contact-form .contact-form-body .form-row .form-label .tag.required-tag {
  color: #E20045;
  border: 1px solid #E20045;
}

.section-contact-form .contact-form-body .form-row .form-label .tag.no-required-tag {
  color: #BABABA;
  border: 1px solid #BABABA;
}

.section-contact-form .contact-form-body .form-row .form-label .notice {
  color: #333333;
  margin-top: 8px;
  font-size: 15px;
  font-size: 1.5rem;
}

@media screen and (max-width: 768px) {
  .section-contact-form .contact-form-body .form-row .form-label .notice {
    font-size: 3.73333vw;
    display: block;
    width: 100%;
  }
}

.section-contact-form .contact-form-body .form-row .form-content {
  width: 65.93%;
}

@media screen and (max-width: 768px) {
  .section-contact-form .contact-form-body .form-row .form-content {
    width: 100%;
  }
}

.section-contact-form .contact-form-body .form-row .form-content p {
  color: #333333;
  margin-top: 14px;
  font-size: 15px;
  font-size: 1.5rem;
}

@media screen and (max-width: 768px) {
  .section-contact-form .contact-form-body .form-row .form-content p {
    font-size: 3.73333vw;
    padding-left: 5.33333vw;
  }
}

.section-contact-form .contact-form-body .form-row .form-content input, .section-contact-form .contact-form-body .form-row .form-content textarea {
  appearance: none;
  border: 1px solid #BABABA;
  height: 50px;
  padding: 0 30px;
  border-radius: 0;
  font-size: 15px;
  font-size: 1.5rem;
}

@media screen and (max-width: 768px) {
  .section-contact-form .contact-form-body .form-row .form-content input, .section-contact-form .contact-form-body .form-row .form-content textarea {
    height: 13.33333vw;
    padding: 0 5.33333vw;
    font-size: 4vw;
  }
}

.section-contact-form .contact-form-body .form-row .form-content input::placeholder, .section-contact-form .contact-form-body .form-row .form-content textarea::placeholder {
  color: #BABABA;
}

.section-contact-form .contact-form-body .form-row .form-content input:focus, .section-contact-form .contact-form-body .form-row .form-content textarea:focus {
  outline: none;
}

.section-contact-form .contact-form-body .form-row .form-content input.input-lg, .section-contact-form .contact-form-body .form-row .form-content textarea.input-lg {
  width: 100%;
}

.section-contact-form .contact-form-body .form-row .form-content input.input-md, .section-contact-form .contact-form-body .form-row .form-content textarea.input-md {
  width: 50%;
}

@media screen and (max-width: 768px) {
  .section-contact-form .contact-form-body .form-row .form-content input.input-md, .section-contact-form .contact-form-body .form-row .form-content textarea.input-md {
    width: 100%;
  }
}

.section-contact-form .contact-form-body .form-row .form-content textarea {
  padding: 15px 30px;
  width: 100%;
  resize: vertical;
  min-height: 150px;
}

@media screen and (max-width: 768px) {
  .section-contact-form .contact-form-body .form-row .form-content textarea {
    padding: 4vw 5.33333vw;
    min-height: 53.33333vw;
  }
}

.section-contact-form .contact-form-body .form-row .form-content .custom-select .s-hidden {
  visibility: hidden;
  padding-right: 10px;
}

.section-contact-form .contact-form-body .form-row .form-content .custom-select .select {
  cursor: pointer;
  display: block;
  width: 50%;
  position: relative;
  height: 50px;
  font-size: 15px;
  font-size: 1.5rem;
}

@media screen and (max-width: 768px) {
  .section-contact-form .contact-form-body .form-row .form-content .custom-select .select {
    width: 100%;
    font-size: 4vw;
  }
}

.section-contact-form .contact-form-body .form-row .form-content .custom-select .styledSelect {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 1px solid #BABABA;
  background-color: white;
  padding: 0 50px 0 30px;
  line-height: 50px;
  border-radius: 5px;
  color: #BABABA;
  overflow: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  -webkit-backface-visibility: hidden;
}

@media screen and (max-width: 768px) {
  .section-contact-form .contact-form-body .form-row .form-content .custom-select .styledSelect {
    padding: 0 13.33333vw 0 5.33333vw;
  }
}

.section-contact-form .contact-form-body .form-row .form-content .custom-select .styledSelect:after {
  content: "\f078";
  color: #333333;
  font-family: FontAwesome, sans-serif;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 20px;
}

.section-contact-form .contact-form-body .form-row .form-content .custom-select .styledSelect:active, .section-contact-form .contact-form-body .form-row .form-content .custom-select .styledSelect.active {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  -webkit-backface-visibility: hidden;
}

.section-contact-form .contact-form-body .form-row .form-content .custom-select .options {
  display: none;
  position: absolute;
  top: calc(100% - 1px);
  right: 0;
  left: 0;
  z-index: 999;
  margin: 0 0;
  padding: 0 0;
  list-style: none;
  background-color: white;
  border: 1px solid #BABABA;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.section-contact-form .contact-form-body .form-row .form-content .custom-select .options li {
  padding: 15px 30px;
  margin: 0 0;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  -webkit-backface-visibility: hidden;
}

@media screen and (max-width: 768px) {
  .section-contact-form .contact-form-body .form-row .form-content .custom-select .options li {
    padding: 4vw 5.33333vw;
  }
}

.section-contact-form .contact-form-body .form-row .form-content .custom-select .options li:hover {
  color: #333333;
  background-color: rgba(255, 241, 0, 0.5);
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  -webkit-backface-visibility: hidden;
}

.section-contact-form .contact-form-footer {
  padding: 50px 0 100px;
  background-color: #FFFFFF;
}

@media screen and (max-width: 768px) {
  .section-contact-form .contact-form-footer {
    padding: 8vw 0 16vw;
  }
}

.section-contact-form .contact-form-footer button {
  appearance: none;
  border: none;
  padding: 22px 30px;
}

@media screen and (max-width: 768px) {
  .section-contact-form .contact-form-footer button {
    width: 100%;
    padding: 4.8vw 6.93333vw;
  }
}

.section-contact-form .contact-form-footer button:hover {
  cursor: pointer;
}

.section-contact-form .contact-form-footer button span, .section-contact-form .contact-form-footer button .fa {
  color: white;
}

.section-contact-form .contact-form-footer button .fa {
  margin-right: 120px;
}

@media screen and (max-width: 768px) {
  .section-contact-form .contact-form-footer button .fa {
    margin-right: 0;
  }
}

.section-contact-form .contact-form-footer button .arrow-right {
  margin-left: 120px;
}

@media screen and (max-width: 768px) {
  .section-contact-form .contact-form-footer button .arrow-right {
    margin-left: 0;
  }
}

.section-contact-form .contact-form-footer button .arrow-right:before {
  background-color: #FFFFFF;
}

.section-contact-form .contact-form-footer button .arrow-right:after {
  border-color: white;
}

.section-contact-form .contact-form-footer.page-contact-confirm .container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
}

@media screen and (min-width: 769px) {
  .section-contact-form .contact-form-footer.page-contact-confirm .container {
    margin-top: -17px;
    margin-bottom: -17px;
  }
}

.section-contact-form .contact-form-footer.page-contact-confirm .btn {
  width: 450px;
}

@media screen and (min-width: 769px) {
  .section-contact-form .contact-form-footer.page-contact-confirm .btn {
    margin: 17px;
  }
}

@media screen and (max-width: 768px) {
  .section-contact-form .contact-form-footer.page-contact-confirm .btn {
    width: 89.33333vw;
    height: 16vw;
  }
}

.section-contact-form .contact-form-footer.page-contact-confirm .btn.btn-solid-yellow {
  padding: 22px 30px;
}

@media screen and (min-width: 769px) {
  .section-contact-form .contact-form-footer.page-contact-confirm .btn.btn-solid-yellow {
    order: -1;
  }
}

@media screen and (max-width: 768px) {
  .section-contact-form .contact-form-footer.page-contact-confirm .btn.btn-solid-yellow {
    margin-top: 5.33333vw;
  }
}

.section-contact-form .contact-form-footer.page-contact-confirm .btn.btn-solid-yellow .arrow-left {
  margin: 0;
}

.section-contact-form .contact-form-footer.page-contact-result .btn {
  width: 450px;
  height: 80px;
}

@media screen and (max-width: 768px) {
  .section-contact-form .contact-form-footer.page-contact-result .btn {
    width: 100%;
    height: 16vw;
  }
}

.section-contact-form .contact-form-footer.page-contact-result .btn .arrow-left {
  margin: 0;
}

.company-profiles {
  padding-top: 50px;
  padding-bottom: 60px;
  background: url("../images/photos/yellow-stripped-pattern.png") white no-repeat right bottom;
}

@media screen and (max-width: 768px) {
  .company-profiles {
    padding-top: 8vw;
    padding-bottom: 13.33333vw;
    background-size: 64vw 69.33333vw;
  }
}

.company-profiles .company-profiles-block {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
}

@media screen and (max-width: 768px) {
  .company-profiles .company-profiles-block {
    width: calc(100% + 10.66666vw);
    position: relative;
    left: -5.33333vw;
  }
}

.company-profiles .company-profiles-block dl {
  width: 42.73%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
  .company-profiles .company-profiles-block dl {
    width: 100%;
  }
}

.company-profiles .company-profiles-block dl dt, .company-profiles .company-profiles-block dl dd {
  border-top: 1px solid #DEDEDE;
  padding-top: 40px;
  padding-bottom: 40px;
}

@media screen and (max-width: 768px) {
  .company-profiles .company-profiles-block dl dt, .company-profiles .company-profiles-block dl dd {
    padding-top: 6.66667vw;
    padding-bottom: 6.66667vw;
    font-size: 3.2vw;
  }
}

.company-profiles .company-profiles-block dl dt {
  width: 34%;
  padding-left: 30px;
  padding-right: 20px;
}

@media screen and (max-width: 768px) {
  .company-profiles .company-profiles-block dl dt {
    padding-left: 5.33333vw;
    padding-right: 5.33333vw;
    width: 30%;
  }
}

.company-profiles .company-profiles-block dl dd {
  width: 66%;
  padding-right: 20px;
}

@media screen and (max-width: 768px) {
  .company-profiles .company-profiles-block dl dd {
    width: 70%;
    padding-right: 5.33333vw;
  }
}

.organization-chart {
  padding: 100px 0;
  background-color: #FAFAFA;
}

@media screen and (max-width: 768px) {
  .organization-chart {
    padding: 8vw 0;
  }
}

.company-access {
  padding: 100px 0;
  background: url("../images/photos/yellow-stripped-pattern-02.png") white no-repeat left top;
}

@media screen and (max-width: 768px) {
  .company-access {
    padding: 21.33333vw 0;
    background-size: 20vw 21.33333vw;
  }
  .company-access .container {
    padding: 0;
  }
}

.relationship-awards {
  padding-bottom: 50px;
  background-color: #fff;
}

@media screen and (max-width: 768px) {
  .relationship-awards {
    padding-bottom: 8vw;
  }
}

.relationship-awards table {
  margin-bottom: 50px;
  width: 100%;
}

.relationship-awards table thead th {
  color: white;
  background-color: #333333;
  text-align: center;
  padding: 9px 30px;
  border: 1px solid white;
  font-size: 15px;
  font-size: 1.5rem;
}

@media screen and (max-width: 768px) {
  .relationship-awards table thead th {
    padding: 3.73333vw 2.66667vw;
    font-size: 3.2vw;
  }
}

.relationship-awards table tbody td {
  padding: 19px 30px;
  background-color: #fafafa;
  border: 1px solid white;
}

@media screen and (max-width: 768px) {
  .relationship-awards table tbody td {
    padding: 3.73333vw 2.66667vw;
    font-size: 3.2vw;
  }
}

.relationship-awards .awards-list {
  display: block;
  max-width: 370px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 110px;
}

@media screen and (max-width: 768px) {
  .relationship-awards .awards-list {
    max-width: 78.66667vw;
    margin-bottom: 21.33333vw;
  }
}

.relationship-awards .awards-list li {
  width: 100%;
  height: 1px;
  background-color: #333333;
  margin-bottom: 40px;
  position: relative;
}

.relationship-awards .awards-list li span {
  display: inline-block;
  background-color: #fff;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media screen and (max-width: 768px) {
  .relationship-awards .awards-list li span {
    font-size: 3.2vw;
  }
}

.relationship-awards .awards-list li span:first-child {
  left: 0;
  padding-right: 5px;
}

@media screen and (max-width: 768px) {
  .relationship-awards .awards-list li span:first-child {
    padding-right: 1.33333vw;
  }
}

.relationship-awards .awards-list li span:last-child {
  right: 0;
  padding-left: 5px;
}

@media screen and (max-width: 768px) {
  .relationship-awards .awards-list li span:last-child {
    padding-left: 1.33333vw;
  }
}

.construction-result {
  background: url("../images/photos/yellow-trapezoid-pattern.png") no-repeat white right bottom;
  padding-bottom: 90px;
}

@media screen and (max-width: 768px) {
  .construction-result {
    padding-bottom: 21.33333vw;
    background-size: 80vw 53.33333vw;
  }
  .construction-result .container {
    padding: 0;
  }
}

.construction-result .construction-result-list {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}

.construction-result .construction-result-list .col {
  width: 42.73%;
}

@media screen and (max-width: 768px) {
  .construction-result .construction-result-list .col {
    width: 100%;
    margin-bottom: 6.66667vw;
  }
  .construction-result .construction-result-list .col:last-of-type {
    margin-bottom: 0;
  }
}

.construction-result .construction-result-list .col ul {
  margin-bottom: 40px;
}

@media screen and (max-width: 768px) {
  .construction-result .construction-result-list .col ul {
    margin-bottom: 6.66667vw;
  }
}

.construction-result .construction-result-list .col ul:last-child {
  margin-bottom: 0;
}

.construction-result .construction-result-list .col ul li {
  padding-left: 40px;
  padding-right: 40px;
}

@media screen and (max-width: 768px) {
  .construction-result .construction-result-list .col ul li {
    padding-left: 5.33333vw;
    padding-right: 5.33333vw;
    font-size: 3.2vw;
  }
}

.construction-result .construction-result-list .col ul li:first-child {
  border-bottom: 1px solid #DEDEDE;
  padding-bottom: 15px;
  margin-bottom: 15px;
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  .construction-result .construction-result-list .col ul li:first-child {
    margin-bottom: 2.66667vw;
    padding-bottom: 2.66667vw;
  }
}

.vision-top-message {
  padding-top: 50px;
  background: url("../images/photos/gray-stripped-pattern.png") #fff no-repeat center top 200px;
  background-size: 100% auto;
}

@media screen and (max-width: 768px) {
  .vision-top-message {
    padding-top: 8vw;
    background: url("../images/photos/gray-stripped-pattern-sp@2x.png") #fff no-repeat center top 92vw;
    background-size: 100% auto;
  }
  .vision-top-message .container {
    padding: 0;
  }
}

.vision-top-message article {
  display: block;
  max-width: 770px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 768px) {
  .vision-top-message article {
    max-width: none;
    padding: 0 5.33333vw;
  }
}

.vision-top-message article p {
  margin-bottom: 30px;
}

@media screen and (max-width: 768px) {
  .vision-top-message article p {
    margin-bottom: 8vw;
  }
}

.vision-top-message article p:last-of-type {
  margin-bottom: 0;
}

.vision-top-message article h3 {
  text-align: center;
  color: #333333;
  font-weight: bold;
  margin-bottom: 50px;
  font-size: 40px;
  font-size: 4rem;
}

@media screen and (max-width: 768px) {
  .vision-top-message article h3 {
    margin-bottom: 8vw;
    font-size: 6.4vw;
  }
}

.vision-top-message article h4 {
  color: #000000;
  margin-top: 10px;
  font-size: 32px;
  font-size: 3.2rem;
}

@media screen and (max-width: 768px) {
  .vision-top-message article h4 {
    margin-top: 1.33333vw;
    font-size: 8vw;
  }
}

.personel-image {
  padding: 100px 0;
  background: url("../images/photos/yellow-trapezoid-pattern-left.png") #fff no-repeat left bottom;
}

@media screen and (max-width: 768px) {
  .personel-image {
    background: url("../images/photos/yellow-trapezoid-pattern-left.png") #fff no-repeat left bottom;
    background-size: 80vw 53.33333vw;
    padding: 21.33333vw 0;
  }
  .personel-image .container {
    padding: 0;
  }
}

.personel-image figure {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.personel-image figure img {
  width: 51.28%;
}

@media screen and (max-width: 768px) {
  .personel-image figure img {
    width: 100%;
  }
}

.personel-image figure figcaption {
  width: 42.73%;
}

@media screen and (max-width: 768px) {
  .personel-image figure figcaption {
    width: 100%;
    padding: 0 5.33333vw;
    margin-top: 8vw;
  }
}

.personel-image figure figcaption p {
  margin-bottom: 30px;
}

@media screen and (max-width: 768px) {
  .personel-image figure figcaption p {
    margin-bottom: 8vw;
    font-size: 3.73333vw;
  }
}

.personel-image figure figcaption p:last-of-type {
  margin-bottom: 0;
}

.about-business {
  padding: 50px 0 100px;
  background: url("../images/photos/gray-stripped-pattern.png") #fff repeat-y center top 200px;
  background-size: 100% auto;
  position: relative;
}

@media screen and (max-width: 768px) {
  .about-business {
    padding: 8vw 0 21.33333vw;
    background: url("../images/photos/gray-stripped-pattern-sp@2x.png") #fff repeat-y center top 37.33333vw;
    background-size: 100% auto;
  }
}

.about-business:before {
  display: block;
  content: '';
  width: 390px;
  height: 200px;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (max-width: 768px) {
  .about-business:before {
    width: 37.33333vw;
    height: 37.33333vw;
  }
}

.about-business .container {
  position: relative;
}

@media screen and (max-width: 768px) {
  .about-business .container {
    padding: 0;
  }
}

.about-business .about-business-list {
  counter-reset: my-sec-counter;
}

@media screen and (max-width: 768px) {
  .about-business .about-business-list {
    padding: 0 5.33333vw;
  }
}

.about-business .about-business-list li {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-bottom: 100px;
  margin-bottom: 100px;
  border-bottom: 1px solid #DEDEDE;
}

@media screen and (max-width: 768px) {
  .about-business .about-business-list li {
    padding-bottom: 10.66667vw;
    margin-bottom: 13.33333vw;
  }
}

@media screen and (min-width: 769px) {
  .about-business .about-business-list li:nth-of-type(even) .desc {
    order: -1;
  }
}

.about-business .about-business-list li:last-of-type {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: none;
}

.about-business .about-business-list li .img {
  width: 51.28%;
}

@media screen and (max-width: 768px) {
  .about-business .about-business-list li .img {
    width: 100%;
  }
}

.about-business .about-business-list li .img img {
  width: 100%;
}

.about-business .about-business-list li .desc {
  width: 42.73%;
}

@media screen and (max-width: 768px) {
  .about-business .about-business-list li .desc {
    width: 100%;
  }
}

.about-business .about-business-list li .desc .title {
  padding: 80px 0 70px 30px;
  position: relative;
  color: #333333;
  font-size: 26px;
  font-size: 2.6rem;
  font-weight: 500;
  z-index: 1;
}

@media screen and (max-width: 768px) {
  .about-business .about-business-list li .desc .title {
    padding-top: 24vw;
    padding-bottom: 9.33333vw;
    font-size: 6.93333vw;
  }
}

.about-business .about-business-list li .desc .title:before {
  display: block;
  counter-increment: my-sec-counter;
  content: "0" counter(my-sec-counter);
  position: absolute;
  top: 0;
  left: 0;
  color: #333333;
  line-height: 1;
  font-size: 85px;
  font-size: 8.5rem;
  font-family: "DIN Condensed Bold", sans-serif;
}

@media screen and (max-width: 768px) {
  .about-business .about-business-list li .desc .title:before {
    top: 4vw;
    font-size: 22.66667vw;
  }
}

.about-business .about-business-list li .desc .title:after {
  display: block;
  content: '';
  width: 73px;
  height: 115px;
  background-color: #FFF100;
  position: absolute;
  top: 20px;
  left: 17px;
  z-index: -1;
}

@media screen and (max-width: 768px) {
  .about-business .about-business-list li .desc .title:after {
    width: 19.46667vw;
    height: 30.66667vw;
    left: 4.53333vw;
    top: 10.66667vw;
  }
}

.employee-interview {
  padding: 0 0 100px;
}

@media screen and (max-width: 768px) {
  .employee-interview {
    padding-bottom: 21.33333vw;
  }
  .employee-interview .container {
    padding: 0;
  }
}

.employee-interview:nth-of-type(odd) {
  background: url("../images/photos/yellow-trapezoid-pattern.png") white no-repeat right bottom;
}

@media screen and (max-width: 768px) {
  .employee-interview:nth-of-type(odd) {
    background: white;
  }
  .employee-interview:nth-of-type(odd) .interview-intro-block .block-content .desc {
    background: url("../images/photos/yellow-trapezoid-pattern.png") white no-repeat right bottom;
    background-size: 80vw 53.33333vw;
  }
}

.employee-interview:nth-of-type(even) {
  background: url("../images/photos/yellow-trapezoid-pattern-left.png") white no-repeat left bottom;
}

@media screen and (max-width: 768px) {
  .employee-interview:nth-of-type(even) {
    background: white;
  }
  .employee-interview:nth-of-type(even) .interview-intro-block .block-content .desc {
    background: url("../images/photos/yellow-trapezoid-pattern-left.png") white no-repeat left bottom;
    background-size: 80vw 53.33333vw;
  }
}

@media screen and (min-width: 769px) {
  .employee-interview:nth-of-type(even) .interview-intro-block .block-content .img {
    order: -1;
  }
}

.employee-interview:nth-of-type(even) .interview-intro-block .block-content .img img {
  width: 100%;
}

.employee-interview .interview-intro-block .block-title {
  color: #333333;
  font-weight: bold;
  text-align: center;
  line-height: 1.8;
  margin-bottom: 50px;
  font-size: 40px;
  font-size: 4rem;
}

@media screen and (max-width: 768px) {
  .employee-interview .interview-intro-block .block-title {
    margin-bottom: 8vw;
    font-size: 6.4vw;
  }
}

.employee-interview .interview-intro-block .block-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.employee-interview .interview-intro-block .block-content .img {
  width: 51.28%;
}

@media screen and (max-width: 768px) {
  .employee-interview .interview-intro-block .block-content .img {
    width: 100%;
  }
}

.employee-interview .interview-intro-block .block-content .img figcaption {
  background-color: #fff;
  margin-top: 20px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  padding-left: 20px;
  padding-right: 20px;
}

@media screen and (max-width: 768px) {
  .employee-interview .interview-intro-block .block-content .img figcaption {
    margin-top: 0;
    padding-left: 6.66667vw;
    padding-right: 6.66667vw;
    box-shadow: 0 0.8vw 1.6vw rgba(0, 0, 0, 0.16);
  }
}

.employee-interview .interview-intro-block .block-content .img figcaption .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 30px 20px 20px;
  border-bottom: 1px solid #DEDEDE;
}

@media screen and (max-width: 768px) {
  .employee-interview .interview-intro-block .block-content .img figcaption .title {
    padding: 5.33333vw 0 4vw;
  }
}

.employee-interview .interview-intro-block .block-content .img figcaption .title .pos {
  color: #000;
  margin-right: 30px;
  font-size: 20px;
  font-size: 2rem;
}

@media screen and (max-width: 768px) {
  .employee-interview .interview-intro-block .block-content .img figcaption .title .pos {
    display: block;
    width: 100%;
    margin-right: 0;
    font-size: 4.8vw;
  }
}

.employee-interview .interview-intro-block .block-content .img figcaption .title .name {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .employee-interview .interview-intro-block .block-content .img figcaption .title .name {
    width: 100%;
  }
}

.employee-interview .interview-intro-block .block-content .img figcaption .title .name .name-en {
  color: #000;
  margin-right: 30px;
  font-size: 15px;
  font-size: 1.5rem;
}

@media screen and (max-width: 768px) {
  .employee-interview .interview-intro-block .block-content .img figcaption .title .name .name-en {
    margin-right: 8vw;
    font-size: 3.73333vw;
  }
}

.employee-interview .interview-intro-block .block-content .img figcaption .title .name .name-jp {
  font-weight: 500;
  font-size: 32px;
  font-size: 3.2rem;
}

@media screen and (max-width: 768px) {
  .employee-interview .interview-intro-block .block-content .img figcaption .title .name .name-jp {
    font-size: 6.4vw;
  }
}

.employee-interview .interview-intro-block .block-content .img figcaption .qualifications {
  overflow: hidden;
  padding: 25px 20px 35px;
}

@media screen and (max-width: 768px) {
  .employee-interview .interview-intro-block .block-content .img figcaption .qualifications {
    padding: 4vw 0 5.33333vw;
  }
}

.employee-interview .interview-intro-block .block-content .img figcaption .qualifications dt {
  width: 120px;
  float: left;
}

@media screen and (max-width: 768px) {
  .employee-interview .interview-intro-block .block-content .img figcaption .qualifications dt {
    width: 100%;
    float: none;
  }
}

.employee-interview .interview-intro-block .block-content .img figcaption .qualifications dd {
  width: calc(100% - 120px);
  float: left;
}

@media screen and (max-width: 768px) {
  .employee-interview .interview-intro-block .block-content .img figcaption .qualifications dd {
    width: 100%;
    float: none;
  }
}

.employee-interview .interview-intro-block .block-content .img figcaption .qualifications dd span {
  display: inline-block;
  margin-right: 10px;
}

@media screen and (max-width: 768px) {
  .employee-interview .interview-intro-block .block-content .img figcaption .qualifications dd span {
    margin-right: 1.33333vw;
  }
}

.employee-interview .interview-intro-block .block-content .desc {
  width: 42.73%;
}

@media screen and (max-width: 768px) {
  .employee-interview .interview-intro-block .block-content .desc {
    width: 100%;
    padding: 0 5.33333vw 8vw;
  }
}

.employee-interview .interview-intro-block .block-content .desc p {
  margin-bottom: 30px;
}

@media screen and (max-width: 768px) {
  .employee-interview .interview-intro-block .block-content .desc p {
    margin-bottom: 5.33333vw;
  }
}

.employee-interview .interview-intro-block .block-content .desc p:last-of-type {
  margin-bottom: 0;
}

.recruit-articles {
  padding: 100px 0;
  background-color: #fff;
}

@media screen and (max-width: 768px) {
  .recruit-articles {
    padding: 8vw 0 21.33333vw;
  }
  .recruit-articles .container {
    padding: 0;
  }
}

.recruit-articles .tags-list {
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .recruit-articles .tags-list {
    padding: 0 5.33333vw;
  }
}

.recruit-articles .tags-list li {
  display: inline-block;
  border: 1px solid #DEDEDE;
  float: left;
  color: #000;
  margin-right: 15px;
  margin-bottom: 15px;
  padding: 5px 15px 5px 33px;
  position: relative;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 768px) {
  .recruit-articles .tags-list li {
    padding: 1.33333vw 4vw 1.33333vw 8.8vw;
    font-size: 3.73333vw;
    margin-right: 4vw;
    margin-bottom: 2.66667vw;
  }
}

.recruit-articles .tags-list li:before {
  display: block;
  content: '\f02b';
  font-family: FontAwesome, sans-serif;
  color: #DEDEDE;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 10px;
}

@media screen and (max-width: 768px) {
  .recruit-articles .tags-list li:before {
    left: 2.66667vw;
  }
}

.recruit-articles article {
  max-width: 760px;
  display: block;
  margin: 0 auto 70px;
}

@media screen and (max-width: 768px) {
  .recruit-articles article {
    max-width: none;
    margin: 0 auto 13.33333vw;
  }
}

.recruit-articles article h3 {
  color: #FFF100;
  background-color: #333333;
  padding: 8px 25px;
  margin-bottom: 15px;
  font-size: 22px;
  font-size: 2.2rem;
}

@media screen and (max-width: 768px) {
  .recruit-articles article h3 {
    font-size: 5.86667vw;
    padding: 2.13333vw 6.66667vw;
    margin-bottom: 5.33333vw;
  }
}

.recruit-articles article p {
  color: #333;
  line-height: 2;
  margin-bottom: 30px;
  padding-left: 25px;
  font-size: 14px;
  font-size: 1.4rem;
}

@media screen and (max-width: 768px) {
  .recruit-articles article p {
    font-size: 3.73333vw;
    padding-left: 6.66667vw;
    padding-right: 6.66667vw;
    margin-bottom: 8vw;
  }
}

.recruit-articles article ul {
  margin-bottom: 30px;
  padding-left: 25px;
}

@media screen and (max-width: 768px) {
  .recruit-articles article ul {
    padding-left: 6.66667vw;
    padding-right: 6.66667vw;
    margin-bottom: 8vw;
  }
}

.recruit-articles article ul li {
  color: #333;
  line-height: 2;
  font-size: 14px;
  font-size: 1.4rem;
}

@media screen and (max-width: 768px) {
  .recruit-articles article ul li {
    font-size: 3.73333vw;
  }
}

.recruit-articles .btn {
  width: 675px;
  height: 120px;
  line-height: 120px;
  padding: 0 40px;
}

@media screen and (max-width: 768px) {
  .recruit-articles .btn {
    width: 89.33333vw;
    height: 16vw;
    line-height: 16vw;
    padding: 0 6.66667vw;
  }
}

.recruit-articles .btn .fa {
  color: white;
  font-size: 50px;
  font-size: 5rem;
}

@media screen and (max-width: 768px) {
  .recruit-articles .btn .fa {
    font-size: 6.66667vw;
  }
}

.recruit-articles .btn span {
  color: white;
  font-size: 30px;
  font-size: 3rem;
}

@media screen and (max-width: 768px) {
  .recruit-articles .btn span {
    font-size: 3.73333vw;
  }
}

.recruit-articles .btn .arrow-right {
  margin-left: 0;
  width: 35px;
  height: 30px;
}

@media screen and (max-width: 768px) {
  .recruit-articles .btn .arrow-right {
    width: 6.66667vw;
    height: 5.33333vw;
  }
}

.recruit-articles .btn .arrow-right:before {
  background-color: white;
}

.recruit-articles .btn .arrow-right:after {
  border-color: white;
  width: 20px;
  height: 20px;
  right: 2px;
}

@media screen and (max-width: 768px) {
  .recruit-articles .btn .arrow-right:after {
    width: 4vw;
    height: 4vw;
  }
}

.recruit-articles .box-link {
  text-align: center;
  max-width: 550px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 768px) {
  .recruit-articles .box-link {
    padding: 0 5.33333vw;
    max-width: none;
  }
}

.recruit-articles .box-link .img {
  position: relative;
  overflow: hidden;
}

.recruit-articles .box-link .img:before {
  display: block;
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  pointer-events: none;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  -webkit-backface-visibility: hidden;
}

.recruit-articles .box-link .img:hover:before {
  opacity: 1;
  visibility: visible;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  -webkit-backface-visibility: hidden;
}

.recruit-articles .box-link .img:hover img {
  transform: scale(1.1);
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  -webkit-backface-visibility: hidden;
}

.recruit-articles .box-link .img img {
  width: 100%;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  -webkit-backface-visibility: hidden;
}

.recruit-articles .box-link .caption {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 35px;
  color: #333333;
  font-weight: bold;
  font-size: 24px;
  font-size: 2.4rem;
}

@media screen and (max-width: 768px) {
  .recruit-articles .box-link .caption {
    font-size: 4.8vw;
    margin-bottom: 5.33333vw;
  }
}

.recruit-articles .box-link .caption span {
  padding: 9px 26px;
  margin-right: 20px;
  color: white;
  background-color: #333333;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 768px) {
  .recruit-articles .box-link .caption span {
    font-size: 3.2vw;
    margin-right: 2.66667vw;
    padding: 1.33333vw 3.2vw;
  }
}
