.recruit-articles {
    padding: 100px 0;
    background-color: #fff;
    @media screen and (max-width: 768px) {
        padding: calc-vw(30, 375) 0 calc-vw(80, 375);
        .container {
            padding: 0;
        }
    }

    .tags-list {
        overflow: hidden;
        @media screen and (max-width: 768px) {
            padding: 0 calc-vw(20, 375);
        }

        li {
            display: inline-block;
            border: 1px solid #DEDEDE;
            float: left;
            color: #000;
            margin-right: 15px;
            margin-bottom: 15px;
            padding: 5px 15px 5px 33px;
            position: relative;
            @include font-rem-px(16);
            @media screen and (max-width: 768px) {
                padding: calc-vw(5, 375) calc-vw(15, 375) calc-vw(5, 375) calc-vw(33, 375);
                font-size: calc-vw(14, 375);
                margin-right: calc-vw(15, 375);
                margin-bottom: calc-vw(10, 375);
            }

            &:before {
                display: block;
                content: '\f02b';
                font-family: FontAwesome, sans-serif;
                color: #DEDEDE;
                position: absolute;
                @include v-align;
                left: 10px;
                @media screen and (max-width: 768px) {
                    left: calc-vw(10, 375);
                }
            }
        }
    }

    article {
        max-width: 760px;
        display: block;
        margin: 0 auto 70px;
        @media screen and (max-width: 768px) {
            max-width: none;
            margin: 0 auto calc-vw(50, 375);
        }

        h3 {
            color: #FFF100;
            background-color: #333333;
            padding: 8px 25px;
            margin-bottom: 15px;
            @include font-rem-px(22);
            @media screen and (max-width: 768px) {
                font-size: calc-vw(22, 375);
                padding: calc-vw(8, 375) calc-vw(25, 375);
                margin-bottom: calc-vw(20, 375);
            }
        }

        p {
            color: #333;
            line-height: 2;
            margin-bottom: 30px;
            padding-left: 25px;
            @include font-rem-px(14);
            @media screen and (max-width: 768px) {
                font-size: calc-vw(14, 375);
                padding-left: calc-vw(25, 375);
                padding-right: calc-vw(25, 375);
                margin-bottom: calc-vw(30, 375);
            }
        }

        ul {
            margin-bottom: 30px;
            padding-left: 25px;
            @media screen and (max-width: 768px) {
                padding-left: calc-vw(25, 375);
                padding-right: calc-vw(25, 375);
                margin-bottom: calc-vw(30, 375);
            }

            li {
                color: #333;
                line-height: 2;
                @include font-rem-px(14);
                @media screen and (max-width: 768px) {
                    font-size: calc-vw(14, 375);
                }
            }
        }
    }

    .btn {
        width: 675px;
        height: 120px;
        line-height: 120px;
        padding: 0 40px;
        @media screen and (max-width: 768px) {
            width: calc-vw(335, 375);
            height: calc-vw(60, 375);
            line-height: calc-vw(60, 375);
            padding: 0 calc-vw(25, 375);
        }

        .fa {
            color: white;
            @include font-rem-px(50);
            @media screen and (max-width: 768px) {
                font-size: calc-vw(25, 375);
            }
        }

        span {
            color: white;
            @include font-rem-px(30);
            @media screen and (max-width: 768px) {
                font-size: calc-vw(14, 375);
            }
        }

        .arrow-right {
            margin-left: 0;
            width: 35px;
            height: 30px;
            @media screen and (max-width: 768px) {
                width: calc-vw(25, 375);
                height: calc-vw(20, 375);
            }

            &:before {
                background-color: white;
            }

            &:after {
                border-color: white;
                width: 20px;
                height: 20px;
                right: 2px;
                @media screen and (max-width: 768px) {
                    width: calc-vw(15, 375);
                    height: calc-vw(15, 375);
                }
            }
        }
    }

    .box-link {
        text-align: center;
        max-width: 550px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        @media screen and (max-width: 768px) {
            padding: 0 calc-vw(20, 375);
            max-width: none;
        }

        .img {
            position: relative;
            overflow: hidden;

            &:before {
                display: block;
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background-color: rgba(0, 0, 0, .5);
                opacity: 0;
                visibility: hidden;
                z-index: 1;
                pointer-events: none;
                @include smooth-transition;
            }

            &:hover {
                &:before {
                    opacity: 1;
                    visibility: visible;
                    @include smooth-transition;
                }
                img {
                    transform: scale(1.1);
                    @include smooth-transition;
                }
            }

            img {
                width: 100%;
                @include smooth-transition;
            }
        }

        .caption {
            display: flex;
            width: 100%;
            align-items: center;
            margin-bottom: 35px;
            color: #333333;
            font-weight: bold;
            @include font-rem-px(24);
            @media screen and (max-width: 768px) {
                font-size: calc-vw(18, 375);
                margin-bottom: calc-vw(20, 375);
            }

            span {
                padding: 9px 26px;
                margin-right: 20px;
                color: white;
                background-color: #333333;
                @include font-rem-px(16);
                @media screen and (max-width: 768px) {
                    font-size: calc-vw(12, 375);
                    margin-right: calc-vw(10, 375);
                    padding: calc-vw(5, 375) calc-vw(12, 375);
                }
            }
        }
    }
}