// PC
@media screen and (min-width: 769px) {
    @keyframes hand-floating {
        0% {
            top: 111px;
        }
        50% {
            top: 100px;
        }
        100% {
            top: 111px;
        }
    }
    @keyframes sdb04 {
        0% {
            transform: translate(0, 0);
        }
        20% {
            transform: translate(0, calc-vw(10, 1920));
        }
        40% {
            transform: translate(0, 0);
        }
    }
}

// Mobile
@media screen and (max-width: 768px) {
    @keyframes hand-floating {
        0% {
            top: 86px;
        }
        50% {
            top: 80px;
        }
        100% {
            top: 86px;
        }
    }
    @keyframes sdb04 {
        0% {
            transform: translate(0, 0);
        }
        20% {
            transform: translate(0, calc-vw(10, 375));
        }
        40% {
            transform: translate(0, 0);
        }
    }
}